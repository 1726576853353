import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Badge, Card, Input, Alert } from "reactstrap";

//React Typist
import Typist from 'react-typist';

//Import Images
import home from "../../../../assets/images/job/home.png";

class Section extends Component {
    render() {
        return (
            <React.Fragment>
                {/* <section className="bg-half-170 pb-0 bg-light d-table w-100"
                style={{ background: `url(${home})` }} >
                  <div className="bg-overlay"></div>
                    <Container data-aos='fade-up'>
                        <Row className="justify-content-center">
                            <Col lg="10">
                                <div className="title-heading text-center mt-4 mt-md-8 pt-md-5">
                                    <Alert color="light" className="alert-pills">
                                        <Badge pill className="rounded-pill bg-success me-1">Start</Badge>
                                        <span className="content"> Fill job <Link to={{pathname:"https://in.indeed.com/cmp/Inofinx-Pvt-Ltd/jobs"}} target="_blank" className="text-primary">application</Link> now !</span>
                                    </Alert>
                                    <h2 className="text-white title-dark"> Job Vacancies </h2>
                                    
                                        
                               
                                    <p className="para-desc mx-auto text-muted">Checkout our open positions and apply today!</p> */}
                                    {/* <Card className="rounded p-4 mt-4">
                                        <Row className="text-start">
                                            <Col lg="5" md="4">
                                                <div className="mb-0">
                                                    <Input type="text" className="form-control" required placeholder="Keywords" />
                                                </div>
                                            </Col>

                                            <Col lg="7" md="8">
                                                <Row className="align-items-center">
                                                    <Col md="4" className="mt-4 mt-sm-0">
                                                        <div className="mb-0">
                                                            <select className="form-control custom-select">
                                                                <option defaultValue="">Location</option>
                                                                <option value="AF">Afghanistan</option>
                                                                <option value="AX">&Aring;land Islands</option>
                                                                <option value="AL">Albania</option>
                                                                <option value="DZ">Algeria</option>
                                                                <option value="AS">American Samoa</option>
                                                                <option value="AD">Andorra</option>
                                                                <option value="AO">Angola</option>
                                                                <option value="AI">Anguilla</option>
                                                                <option value="AQ">Antarctica</option>
                                                            </select>
                                                        </div>
                                                    </Col>

                                                    <Col md="4" className="mt-4 mt-sm-0">
                                                        <div className="mb-0">
                                                            <select className="form-control custom-select">
                                                                <option defaultValue="">Job Types</option>
                                                                <option value="4">Accounting</option>
                                                                <option value="1">IT & Software</option>
                                                                <option value="3">Marketing</option>
                                                                <option value="5">Banking</option>
                                                            </select>
                                                        </div>
                                                    </Col>

                                                    <Col md="4" className="mt-4 mt-sm-0">
                                                        <div className="d-grid">
                                                            <input type="submit" id="search" name="search" className="searchbtn btn btn-primary" value="Search" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <p className="text-muted mb-0 mt-4"><span className="text-dark">Keywords :</span> Web Developer, Web Designer, Php Developer, IOS Developer, etc...</p> */}
                                {/* </div>
                            </Col>

                           
                        </Row>
                    </Container>
                </section> */}
            </React.Fragment>
        );
    }
}

export default Section;