import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

//Import Icons
import FeatherIcon from "feather-icons-react";

//import image
// import about1 from "../../assets/images/company/about1.png";

export default class Feature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      features: [
        {
          id: 1,
          icon: "uil uil-airplay d-block rounded h3 mb-0 mx-auto",
          heading: "SaaS Platform for Lawyers & Lawfirms",
          description: "Short description",
        },
        {
          id: 2,
          icon: "uil uil-clipboard-alt d-block rounded h3 mb-0 mx-auto",
          heading: "Crowdfunding aggregator Platform",
          description: "Short description2",
        },
        {
          id: 3,
          icon: "uil uil-credit-card-search d-block rounded h3 mb-0 mx-auto",
          heading: "SaaS Platform for IoT & Network Management",
          description: "Short description3",
        },
        {
          id: 4,
          icon: "uil uil-credit-card-search d-block rounded h3 mb-0 mx-auto",
          heading: "Marketing & Business Development Solutions",
          description: "Short description4",
        },
      ],
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    AOS.init({duration:2000});
    AOS.refresh();
  }
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light">
          <Container  data-aos="fade-up">
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="features-absolute">
                  <Row className="justify-content-center">
                    {this.state.features.map((items, key) => (
                      <Col lg={3} md={6} xs={12} key={key}>
                        <Card className="features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center justify-content-center">
                          <div className="icons text-primary text-center mx-auto">
                            <i className={items.icon}></i>
                          </div>

                          <CardBody className="p-0 content">
                            <h5 className="mt-4">
                              <Link to="#" className="title text-dark">
                                {items.heading}
                              </Link>
                            </h5>
                            <p className="text-muted">
                              {items.description}
                            </p>

                            <Link to="#" className="text-primary">
                              Read More{" "}
                              <i>
                                <FeatherIcon
                                  icon="chevron-right"
                                  className="fea icon-sm"
                                />
                              </i>
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>

          
         
        </section>
      </React.Fragment>
    );
  }
}
