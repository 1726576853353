import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";

import CountUp from "react-countup";


import about2 from "./Images/about2.png";

const Section2 = () => {
    return (
      <React.Fragment>
<section data-aos='fade-up'>
          <Container>
            <Row className="align-items-center" id="counter">
              <Col md={6}>
                <img src={about2} className="img-fluid" alt="" />
              </Col>

              <Col md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-4">
{/*                   <div className="d-flex mb-4">
                    <span className="text-primary h1 mb-0">
                      <span className="counter-value display-1 fw-bold">
                        <CountUp start={0} end={25} duration={3} />
                      </span>
                      +
                    </span>
                    <span className="h6 align-self-end ms-2">
                      Years <br /> Experience
                    </span>
                  </div> */}
                  <div className="section-title" data-aos='fade-up'>
                    <h4 className="title mb-4">Who we are?</h4>

                    <p>INOFINIX is a leading information technology start up providing niche technology solutions & services to global customers.</p>
                    <p>Founded in 2018, providing niche technology solutions to the global clients to enable them to innovate and achieve their aspirations.</p>
                    <p>Our services are based on maximum automation & maximum innovation, this allows us to offer very attractive & competitive. Provided by the rich Business consulting and IT experience of our founders, we provide cutting-edge technology solutions & services.
                      Guided by our <a href="#vision"><u>Vision and Mission</u></a> & <a href="#values"><u>Core Values</u></a> we hire and nurture a highly talented and moitivated <a href="#team"><u>team</u></a>.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        </React.Fragment>
           );  
        };
        export default Section2;