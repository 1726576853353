// import react from "react";
import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

// import file
import Section from "./Section";
import WhatWeDo from './WhatWeDo';
import Features from "./Features";
import WorkProcess from "./WorkProcess";
import Blog from "./Blog";
import Slider from "react-slick";      

//Import Images
import img1 from "../../assets/images/client/amazon.svg";
import img2 from "../../assets/images/client/google.svg";
import mongodb from "../../assets/images/client/mongodb.svg";
import awscloud from "../../assets/images/client/awscloud.png";
import GoLogoBlue from "../../assets/images/client/GoLogoBlue.png";
import googlecloud from "../../assets/images/client/googlecloud.png";
import grpc from "../../assets/images/client/grpc.png";
import http from "../../assets/images/client/http.png";
import influxdb from "../../assets/images/client/influxdb.png";
import java from "../../assets/images/client/java.png";
import microsoftazure from "../../assets/images/client/microsoftazure.png";
import neo4j from "../../assets/images/client/neo4j.png";
import nextcloud from "../../assets/images/client/nextcloud.png";
import swiftlogo from "../../assets/images/client/swiftlogo.png";


import MindPower from "./MindPower";
import Popup from "../../components/Layout/popup";

const Index = () => {
  const partners = [
    { image: img1 },
    { image: img2 },
    { image: mongodb },
    { image: GoLogoBlue },
    { image: googlecloud },
    { image: http },
    { image: microsoftazure },
    { image: neo4j },
    // { image: nextcloud },
    { image: swiftlogo },
    { image: java },
    { image: awscloud },
    { image: grpc },
    { image: influxdb },
  ];

  useEffect(() => {
    document.body.classList = "";
    document.getElementById("topnav").classList.add("bg-secondary-z");

    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
      document.getElementById("topnav").classList.remove("bg-secondary-z");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
      document.getElementById("topnav").classList.add("bg-secondary-z");
    }
  };
  const settings = {
    className: "center",
    infinite: true,
    speed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    // arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease",
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    useCSS: true
  }


  return (
    <React.Fragment>
      <Section />
      {/* Partner */}
      <section className="py-4 bg-light" style={{height:"150px"}}>
        <Container>
          {/* partners */}
          <Row className="partner">
          <Slider {...settings} style={{margin:"0px",marginTop:'-102px'}}>
            {partners.map((partner, key) => (
              <Col
                lg={2}
                md={2}
                sm={2}
                key={key}
                className="text-center py-4"
                style={{marginTop:'0px'}}
              >
               <img
                  src={partner.image}
                  className="avatar avatar-sm"
                  style={{marginTop:'0px',aspectRatio:1,objectFit:'contain',mixBlendMode:'color-burn'}}
                  width="100px"
                  height="250px"
                  alt="Inofinix"
                />
              </Col>
            ))}
               </Slider>
          </Row>
        </Container>
      </section>
      {/* What we do section */}
      <WhatWeDo />
      {/* Feature */}
      <Features />
      {/* WorkProcess */}
      <section className="section  bg-light">
        <WorkProcess /></section>
        {/* <section className="section"> */}
        {/* <MindPower /> */}
      {/* </section> */}
      {/* Blog */}
      <Popup />
    </React.Fragment>
  );
}

export default Index;
