import React from "react";

import { Card, Col, Container, Row } from "reactstrap";



// Import Images


const Section1 = () => {
    return (
        <React.Fragment>
            <section style={{marginTop:'34px'}}>
                <Container data-aos='fade-up'>
                    <div className="container">
                        <div className="row">

                            <div className="col-12 col-md-6 col-lg-4" >
                                <div className="features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0 card" style={{height:'250px'}}>
                                    <span className="h1 icon2 text-primary">
                                        <i className="uil uil-briefcase"></i>
                                    </span>
                                    <div className="p-0 content card-body">
                                        <h5>Our Vision</h5>
                                        <p className="para text-muted mb-0">To be a world class technology company helping our clients to innovate and succeed.</p>
                                    </div></div></div>

                            <div className="col-12 col-md-6 col-lg-4" >
                                <div className="features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0 card" style={{height:'250px'}}>
                                    <span className="h1 icon2 text-primary"><i className="uil uil-rocket"></i></span>
                                    <div className="p-0 content card-body">
                                        <h5>Our Mission</h5>
                                        <p className="para text-muted mb-0">To offer the cutting-edge technology solutions to the organizations across the globe to achieve their aspirations.</p></div></div></div>

                            <div className="col-12 col-md-6 col-lg-4" >
                                <div className="features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0 card" style={{height:'250px'}}>
                                    <span className="h1 icon2 text-primary"><i className="uil uil-layer-group"></i></span>
                                    <div className="p-0 content card-body">
                                        <h5>Team</h5>
                                        <p className="para text-muted mb-0">We are talented and enthusiastic team bringing in wide range of experience.</p></div></div></div>

                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default Section1;
