import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import ReactDrawer from 'react-drawer';
import { Col} from 'reactstrap';
import 'react-drawer/lib/react-drawer.css';
import {
  Container,
} from 'reactstrap';


class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpenShop: false,
      wishlistModal: false,
      dropdownIsOpen: false,
      open: false,
      position: 'right',
      dropdownOpen: false,
      landing: false,
      components: false,
      demo: false,
      doc: false,
      pages: false,
      company: false,
      account: false,
      email: false,
      blog: false,
      case: false,
      auth: false,
      login: false,
      signup: false,
      reset: false,
      utility: false,
      special: false,
      contact: false,
      multi: false,
      level2: false,
      isScrolled: true
    };
    this.toggleLine = this.toggleLine.bind(this);
    this.toggleDropdownShop.bind(this);
    this.toggleWishlistModal.bind(this);
    this.toggleDropdownIsOpen.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.toggleDropdown.bind(this);
    this.togglemodal.bind(this);
  }

  toggleRightDrawer = () => {
    this.setState({ position: 'right' });
    this.setState({ open: !this.state.open });
  };
  onDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleWishlistModal = () => {
    this.setState((prevState) => ({
      wishlistModal: !prevState.wishlistModal,
    }));
  };

  toggleDropdownShop = () => {
    this.setState({
      dropdownOpenShop: !this.state.dropdownOpenShop,
    });
  };
  toggleDropdownIsOpen = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }
  initMenu() {
    this.activateParentDropdown();
  }


  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }
  activateMenu() {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {

        var matchingMenuItem = null;
        for (var idx = 0; idx < menuItems.length; idx++) {
            if (menuItems[idx].href === window.location.href) {
                matchingMenuItem = menuItems[idx];
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add('active');
            const immediateParent = matchingMenuItem.closest('li');
            if (immediateParent) {
                immediateParent.classList.add('active');
            }
            const parent = matchingMenuItem.closest(".parent-menu-item");
            if (parent) {
                parent.classList.add('active');
                var parentMenuitem = parent.querySelector('.menu-item');
                if (parentMenuitem) {
                    parentMenuitem.classList.add('active');
                }
                const parentOfParent = parent.closest(".parent-menu-item-sub");
                if (parentOfParent) {
                    parentOfParent.classList.add('active');
                }
            } else {
                const parentOfParent = matchingMenuItem.closest(".parent-menu-item-sub");
                if (parentOfParent) {
                    parentOfParent.classList.add("active");
                }
            }

        }
    }
}
  activateParentDropdown() {
    var menuItems = document.getElementsByClassName("sub-menu-item");

    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');

        const immediateParent = matchingMenuItem.closest('li');
        if (immediateParent) {
          immediateParent.classList.add('active');
        }
        const parent = immediateParent.closest(".parent-menu-item");

        if (parent) {
          parent.classList.add('active');

          var parentMenuitem = parent.querySelector('a');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }
          var parentOfParent = parent.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          parentOfParent = matchingMenuItem.closest(".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
      return false;
    }
    return false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
   
    document.getElementById("topnav").classList.add("bg-secondary-y");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }
  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        document.getElementById("topnav").classList.add("bg-secondary-y");
        document.getElementById("topnav").classList.remove("bg-secondary-x");
        document.getElementById("topnav").classList.add("nav-sticky");
        navBar.classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.add("bg-secondary-y");
        document.getElementById("topnav").classList.remove("bg-secondary-x");
        document.getElementById("topnav").classList.remove("nav-sticky");
        navBar.classList.remove("nav-sticky");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };
  isToogleMenu = () => {
    const isToggle = document.getElementById("isToggle");
    if (isToggle) {
        isToggle.classList.toggle("open");
        var isOpen = document.getElementById('navigation');
        console.log("navgiationId", isOpen);
        if (isOpen.style.display === "block") {
            isOpen.style.display = "none";
        } else {
            isOpen.style.display = "block";
        }
    }
};
  render() {
    return (
      <React.Fragment>
        {this.props.tagline ? this.props.tagline : null}

        <header id="topnav" className="sticky x-topbar ">
          <Container className="col-12"> 
            <div >
              <div >
              {!this.state.isScrolled ? (
                <Link className="logo" to="/">
                <span className="logo-light-mode">
                  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="31" viewBox="-59.342 0.906 116 24" fill="none">
                    <g id="arrow" transform="matrix(0.602106, 0, 0, 0.602106, -54.671295, 3.836915)">
                      <g id="Vector">
                        <path d="M18.9223 30.0098C17.6023 30.0098 16.5322 28.9398 16.5322 27.6198L16.5322 11.9137C16.5322 10.5937 17.6023 9.5236 18.9223 9.5236C20.2423 9.5236 21.3123 10.5937 21.3123 11.9137L21.3123 27.6198C21.3123 28.9398 20.2423 30.0098 18.9223 30.0098Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                        <path d="M1.55355 29.2823C0.620178 28.3489 0.620178 26.8356 1.55355 25.9022L17.2323 10.2235C18.1656 9.29016 19.6789 9.29016 20.6123 10.2235C21.5457 11.1569 21.5457 12.6702 20.6123 13.6036L4.93361 29.2823C4.00024 30.2157 2.48693 30.2157 1.55355 29.2823Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                        <path d="M0.826111 11.9135C0.826111 10.5935 1.89618 9.52344 3.21617 9.52344L18.9223 9.5236C20.2423 9.5236 21.3123 10.5937 21.3123 11.9137C21.3123 13.2337 20.2423 14.3036 18.9223 14.3036H3.21617C1.89618 14.3036 0.826111 13.2335 0.826111 11.9135Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                      </g>
                      <path id="Union" d="M13.0714 0.114746C11.5628 0.114746 10.3399 1.33768 10.3399 2.84624C10.3399 4.35481 11.5628 5.57774 13.0714 5.57774H25.3631L25.3631 17.8695C25.3631 19.378 26.586 20.601 28.0946 20.601C29.6032 20.601 30.8261 19.378 30.8261 17.8695L30.8261 2.84894C30.8261 2.84804 30.8261 2.84714 30.8261 2.84624C30.8261 2.75196 30.8213 2.65879 30.812 2.56696C30.6721 1.18959 29.5089 0.114746 28.0946 0.114746H13.0714Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                    </g>
                    <g transform="matrix(0.8, 0, 0, 0.8, -18.007002, 0.705061)">
                      <path d="M -15.035 24.239 L -15.035 5.118 L -11.539 5.118 L -11.539 24.239 L -15.035 24.239 Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                      <path d="M -6.822 24.239 L -6.822 10.689 L -3.732 10.689 L -3.461 12.983 C -3.042 12.181 -2.441 11.544 -1.657 11.073 C -0.855 10.599 0.083 10.359 1.156 10.359 C 2.835 10.359 4.135 10.89 5.065 11.945 C 5.995 13 6.456 14.551 6.456 16.591 L 6.456 24.239 L 2.96 24.239 L 2.96 16.919 C 2.96 15.754 2.724 14.859 2.253 14.239 C 1.775 13.619 1.038 13.313 0.035 13.313 C -0.945 13.313 -1.757 13.659 -2.393 14.351 C -3.013 15.04 -3.325 16.008 -3.325 17.246 L -3.325 24.239 L -6.822 24.239 Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                      <path d="M 17.396 24.569 C 16.088 24.569 14.902 24.268 13.847 23.667 C 12.809 23.065 11.978 22.239 11.358 21.179 C 10.757 20.105 10.456 18.867 10.456 17.464 C 10.456 16.06 10.769 14.834 11.388 13.779 C 12.007 12.699 12.835 11.862 13.875 11.261 C 14.932 10.659 16.115 10.359 17.426 10.359 C 18.717 10.359 19.885 10.659 20.922 11.261 C 21.978 11.862 22.809 12.699 23.411 13.779 C 24.03 14.834 24.336 16.06 24.336 17.464 C 24.336 18.867 24.03 20.105 23.411 21.179 C 22.809 22.239 21.978 23.065 20.922 23.667 C 19.867 24.268 18.694 24.569 17.396 24.569 Z M 17.396 21.539 C 18.311 21.539 19.1 21.199 19.775 20.524 C 20.451 19.834 20.787 18.815 20.787 17.464 C 20.787 16.119 20.451 15.105 19.775 14.434 C 19.1 13.739 18.316 13.396 17.426 13.396 C 16.495 13.396 15.695 13.739 15.02 14.434 C 14.366 15.105 14.035 16.119 14.035 17.464 C 14.035 18.815 14.366 19.834 15.02 20.524 C 15.695 21.199 16.489 21.539 17.396 21.539 Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                      <path d="M 29.187 24.239 L 29.187 13.614 L 27.33 13.614 L 27.33 10.689 L 29.187 10.689 L 29.187 9.103 C 29.187 7.464 29.595 6.299 30.415 5.607 C 31.255 4.917 32.383 4.569 33.804 4.569 L 35.307 4.569 L 35.307 7.546 L 34.352 7.546 C 33.751 7.546 33.32 7.664 33.067 7.9 C 32.814 8.139 32.683 8.539 32.683 9.103 L 32.683 10.689 L 35.608 10.689 L 35.608 13.614 L 32.683 13.614 L 32.683 24.239 L 29.187 24.239 Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                      <path d="M 41.355 8.584 C 40.715 8.584 40.191 8.396 39.773 8.013 C 39.371 7.629 39.171 7.145 39.171 6.562 C 39.171 5.979 39.371 5.506 39.773 5.141 C 40.191 4.759 40.715 4.569 41.355 4.569 C 41.995 4.569 42.514 4.759 42.915 5.141 C 43.334 5.506 43.54 5.979 43.54 6.562 C 43.54 7.145 43.334 7.629 42.915 8.013 C 42.514 8.396 41.995 8.584 41.355 8.584 Z M 39.607 24.239 L 39.607 10.689 L 43.104 10.689 L 43.104 24.239 L 39.607 24.239 Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                      <path d="M 47.848 24.239 L 47.848 10.689 L 50.935 10.689 L 51.209 12.983 C 51.627 12.181 52.229 11.544 53.013 11.073 C 53.815 10.599 54.753 10.359 55.825 10.359 C 57.5 10.359 58.803 10.89 59.735 11.945 C 60.66 13 61.126 14.551 61.126 16.591 L 61.126 24.239 L 57.63 24.239 L 57.63 16.919 C 57.63 15.754 57.394 14.859 56.916 14.239 C 56.445 13.619 55.708 13.313 54.705 13.313 C 53.72 13.313 52.913 13.659 52.275 14.351 C 51.655 15.04 51.344 16.008 51.344 17.246 L 51.344 24.239 L 47.848 24.239 Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                      <path d="M 67.449 8.584 C 66.813 8.584 66.281 8.396 65.863 8.013 C 65.462 7.629 65.261 7.145 65.261 6.562 C 65.261 5.979 65.462 5.506 65.863 5.141 C 66.281 4.759 66.813 4.569 67.449 4.569 C 68.086 4.569 68.605 4.759 69.005 5.141 C 69.424 5.506 69.635 5.979 69.635 6.562 C 69.635 7.145 69.424 7.629 69.005 8.013 C 68.605 8.396 68.086 8.584 67.449 8.584 Z M 65.697 24.239 L 65.697 10.689 L 69.195 10.689 L 69.195 24.239 L 65.697 24.239 Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                      <path d="M 72.655 24.239 L 77.575 17.464 L 72.655 10.689 L 76.397 10.689 L 79.84 15.499 L 83.255 10.689 L 87.028 10.689 L 82.081 17.464 L 87.028 24.239 L 83.255 24.239 L 79.84 19.434 L 76.397 24.239 L 72.655 24.239 Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                    </g>
                  </svg></span>
                </Link>
              ) : (
                <Link className="logo" to="/">
                  <span className="logo-light-mode">
                    <svg xmlns="http://www.w3.org/2000/svg" width="150px" height="31" viewBox="-59.342 0.906 116 24" fill="none">
                      <g id="arrow" transform="matrix(0.602106, 0, 0, 0.602106, -54.671295, 3.836915)">
                        <g id="Vector">
                          <path d="M18.9223 30.0098C17.6023 30.0098 16.5322 28.9398 16.5322 27.6198L16.5322 11.9137C16.5322 10.5937 17.6023 9.5236 18.9223 9.5236C20.2423 9.5236 21.3123 10.5937 21.3123 11.9137L21.3123 27.6198C21.3123 28.9398 20.2423 30.0098 18.9223 30.0098Z" fill="#5629B7" />
                          <path d="M1.55355 29.2823C0.620178 28.3489 0.620178 26.8356 1.55355 25.9022L17.2323 10.2235C18.1656 9.29016 19.6789 9.29016 20.6123 10.2235C21.5457 11.1569 21.5457 12.6702 20.6123 13.6036L4.93361 29.2823C4.00024 30.2157 2.48693 30.2157 1.55355 29.2823Z" fill="#5629B7" />
                          <path d="M0.826111 11.9135C0.826111 10.5935 1.89618 9.52344 3.21617 9.52344L18.9223 9.5236C20.2423 9.5236 21.3123 10.5937 21.3123 11.9137C21.3123 13.2337 20.2423 14.3036 18.9223 14.3036H3.21617C1.89618 14.3036 0.826111 13.2335 0.826111 11.9135Z" fill="#5629B7" />
                        </g>
                        <path id="Union" d="M13.0714 0.114746C11.5628 0.114746 10.3399 1.33768 10.3399 2.84624C10.3399 4.35481 11.5628 5.57774 13.0714 5.57774H25.3631L25.3631 17.8695C25.3631 19.378 26.586 20.601 28.0946 20.601C29.6032 20.601 30.8261 19.378 30.8261 17.8695L30.8261 2.84894C30.8261 2.84804 30.8261 2.84714 30.8261 2.84624C30.8261 2.75196 30.8213 2.65879 30.812 2.56696C30.6721 1.18959 29.5089 0.114746 28.0946 0.114746H13.0714Z" fill="#5629B7" />
                      </g>
                      <g transform="matrix(0.8, 0, 0, 0.8, -18.007002, 0.705061)">
                        <path d="M -15.035 24.239 L -15.035 5.118 L -11.539 5.118 L -11.539 24.239 L -15.035 24.239 Z" style={{ fill: 'rgb(0, 0, 0)' }} />
                        <path d="M -6.822 24.239 L -6.822 10.689 L -3.732 10.689 L -3.461 12.983 C -3.042 12.181 -2.441 11.544 -1.657 11.073 C -0.855 10.599 0.083 10.359 1.156 10.359 C 2.835 10.359 4.135 10.89 5.065 11.945 C 5.995 13 6.456 14.551 6.456 16.591 L 6.456 24.239 L 2.96 24.239 L 2.96 16.919 C 2.96 15.754 2.724 14.859 2.253 14.239 C 1.775 13.619 1.038 13.313 0.035 13.313 C -0.945 13.313 -1.757 13.659 -2.393 14.351 C -3.013 15.04 -3.325 16.008 -3.325 17.246 L -3.325 24.239 L -6.822 24.239 Z" style={{ fill: 'rgb(0, 0, 0)' }} />
                        <path d="M 17.396 24.569 C 16.088 24.569 14.902 24.268 13.847 23.667 C 12.809 23.065 11.978 22.239 11.358 21.179 C 10.757 20.105 10.456 18.867 10.456 17.464 C 10.456 16.06 10.769 14.834 11.388 13.779 C 12.007 12.699 12.835 11.862 13.875 11.261 C 14.932 10.659 16.115 10.359 17.426 10.359 C 18.717 10.359 19.885 10.659 20.922 11.261 C 21.978 11.862 22.809 12.699 23.411 13.779 C 24.03 14.834 24.336 16.06 24.336 17.464 C 24.336 18.867 24.03 20.105 23.411 21.179 C 22.809 22.239 21.978 23.065 20.922 23.667 C 19.867 24.268 18.694 24.569 17.396 24.569 Z M 17.396 21.539 C 18.311 21.539 19.1 21.199 19.775 20.524 C 20.451 19.834 20.787 18.815 20.787 17.464 C 20.787 16.119 20.451 15.105 19.775 14.434 C 19.1 13.739 18.316 13.396 17.426 13.396 C 16.495 13.396 15.695 13.739 15.02 14.434 C 14.366 15.105 14.035 16.119 14.035 17.464 C 14.035 18.815 14.366 19.834 15.02 20.524 C 15.695 21.199 16.489 21.539 17.396 21.539 Z" style={{ fill: 'rgb(0, 0, 0)' }} />
                        <path d="M 29.187 24.239 L 29.187 13.614 L 27.33 13.614 L 27.33 10.689 L 29.187 10.689 L 29.187 9.103 C 29.187 7.464 29.595 6.299 30.415 5.607 C 31.255 4.917 32.383 4.569 33.804 4.569 L 35.307 4.569 L 35.307 7.546 L 34.352 7.546 C 33.751 7.546 33.32 7.664 33.067 7.9 C 32.814 8.139 32.683 8.539 32.683 9.103 L 32.683 10.689 L 35.608 10.689 L 35.608 13.614 L 32.683 13.614 L 32.683 24.239 L 29.187 24.239 Z" style={{ fill: 'rgb(0, 0, 0)' }} />
                        <path d="M 41.355 8.584 C 40.715 8.584 40.191 8.396 39.773 8.013 C 39.371 7.629 39.171 7.145 39.171 6.562 C 39.171 5.979 39.371 5.506 39.773 5.141 C 40.191 4.759 40.715 4.569 41.355 4.569 C 41.995 4.569 42.514 4.759 42.915 5.141 C 43.334 5.506 43.54 5.979 43.54 6.562 C 43.54 7.145 43.334 7.629 42.915 8.013 C 42.514 8.396 41.995 8.584 41.355 8.584 Z M 39.607 24.239 L 39.607 10.689 L 43.104 10.689 L 43.104 24.239 L 39.607 24.239 Z" style={{ fill: 'rgb(0, 0, 0)' }} />
                        <path d="M 47.848 24.239 L 47.848 10.689 L 50.935 10.689 L 51.209 12.983 C 51.627 12.181 52.229 11.544 53.013 11.073 C 53.815 10.599 54.753 10.359 55.825 10.359 C 57.5 10.359 58.803 10.89 59.735 11.945 C 60.66 13 61.126 14.551 61.126 16.591 L 61.126 24.239 L 57.63 24.239 L 57.63 16.919 C 57.63 15.754 57.394 14.859 56.916 14.239 C 56.445 13.619 55.708 13.313 54.705 13.313 C 53.72 13.313 52.913 13.659 52.275 14.351 C 51.655 15.04 51.344 16.008 51.344 17.246 L 51.344 24.239 L 47.848 24.239 Z" style={{ fill: 'rgb(0, 0, 0)' }} />
                        <path d="M 67.449 8.584 C 66.813 8.584 66.281 8.396 65.863 8.013 C 65.462 7.629 65.261 7.145 65.261 6.562 C 65.261 5.979 65.462 5.506 65.863 5.141 C 66.281 4.759 66.813 4.569 67.449 4.569 C 68.086 4.569 68.605 4.759 69.005 5.141 C 69.424 5.506 69.635 5.979 69.635 6.562 C 69.635 7.145 69.424 7.629 69.005 8.013 C 68.605 8.396 68.086 8.584 67.449 8.584 Z M 65.697 24.239 L 65.697 10.689 L 69.195 10.689 L 69.195 24.239 L 65.697 24.239 Z" style={{ fill: 'rgb(0, 0, 0)' }} />
                        <path d="M 72.655 24.239 L 77.575 17.464 L 72.655 10.689 L 76.397 10.689 L 79.84 15.499 L 83.255 10.689 L 87.028 10.689 L 82.081 17.464 L 87.028 24.239 L 83.255 24.239 L 79.84 19.434 L 76.397 24.239 L 72.655 24.239 Z" style={{ fill: 'rgb(0, 0, 0)' }} />
                      </g>
                    </svg>
                  </span>
                </Link>
              )}
              <div className="menu-extras" style={{marginRight:'50px'}}>
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? 'navbar-toggle open' : 'navbar-toggle'
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
              </div>
              <div id="navigation" style={{ display: this.state.isOpen ? 'block' : 'none' }}>
                
            <ul
                className="navigation-menu nav-dark togglericon"
                id="top-menu"
                // style={{
                // //  display:"flex",
                // //  justifyContent:'end',
                // //   padding: '30px auto',
                // //   marginLeft: '20px',
                // float:'right'
                // }}
               
              >
                 <li className="has-submenu parent-parent-menu-item">
                  <Link to="/services" className="sub-menu-item" style={{ color: 'white !important' }}>
                    SERVICES
                  </Link>
                </li>
                {/* <li>
                  <Link to="/work" className="sub-menu-item hover:underLine " style={{ color: 'white !important' }}>
                    WORK
                  </Link>
                </li> */}
                {/* <li className="has-submenu parent-parent-menu-item">
                  <Link
                    to="/blog" className="sub-menu-item" style={{ color: 'white !important' }}
                  >
                    BLOGS
                  </Link>
                </li> */}

                {/* <li className="has-submenu parent-parent-menu-item">
                  <Link
                    to="/careers" className="sub-menu-item" style={{ color: 'white !important' }}
                  >
                    CAREERS
                  </Link>
                </li> */}
                <span className="menu-arrow"></span>

                <li className="has-submenu parent-parent-menu-item">
                  <Link
                    to="/about" className="sub-menu-item" style={{ color: 'white !important' }}
                  >
                    ABOUT
                  </Link>
                </li>
                <li className="has-submenu parent-parent-menu-item">
                  <Link
                    to="/contact" className="sub-menu-item" style={{ color: 'white !important' }}
                  >
                    <button type="button" style={{marginTop:'-6px'}} className="btn btn-primary btn-sm">
                      CONTACT
                    </button>
                  </Link>
                </li>
              </ul>
              </div>
              
              </div>
           </Container>
          {/* </ className="d-flex"> */}
        </header>
        <ReactDrawer
                    open={this.state.open}
                    position={this.state.position}
                    onClose={this.onDrawerClose}
                >
                    {/* <RightSidebar onClose={this.onDrawerClose} /> */}
                </ReactDrawer>

      </React.Fragment>
    );
  }
}

export default withRouter(Topbar);

