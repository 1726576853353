import React from 'react';
import { Container, Row, Col } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
const KeyFeature = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        AOS.refresh();
      }, []);
    return (
        <React.Fragment>
            <section className="section">
                <Container  data-aos="fade-up">
                    <Row className="justify-content-center">
                        <Col className="col-12 text-center">
                            <div className="section-title mb-4 pb-2">
                                <h4 className="title mb-4">Key Features</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Start working with <span className="text-primary fw-bold">Inofinix</span> that can provide everything you need to be efficient and grow your business</p>
                            </div>
                        </Col>
                    </Row>

                    <Row data-aos="fade-up">
                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div className="icon text-center rounded-circle me-3">                                   
                                    <FeatherIcon icon="monitor" className="fea icon-ex-md"/>
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">Advanced technology stack</h4>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div className="icon text-center rounded-circle me-3">                                 
                                    <FeatherIcon icon="heart" className="fea icon-ex-md"/>
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">UX/UI design with high usability</h4>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div className="icon text-center rounded-circle me-3">
                                    <FeatherIcon icon="eye" className="fea icon-ex-md"/>
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">High performance solution</h4>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div className="icon text-center rounded-circle me-3">
                                    <FeatherIcon icon="bold" className="fea icon-ex-md"/>
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">Scalable infrastructure</h4>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div className="icon text-center rounded-circle me-3">                                 
                                    <FeatherIcon icon="feather" className="fea icon-ex-md"/>
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">Efficiency and automation</h4>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="mt-4 pt-2">
                            <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div className="icon text-center rounded-circle me-3">
                                    <FeatherIcon icon="settings" className="fea icon-ex-md"/>
                                </div>
                                <div className="flex-1">
                                    <h4 className="title mb-0">Easy to customize & maintain</h4>
                                </div>
                            </div>
                        </Col>

                        <div className="col-12 mt-4 pt-2 text-center">
                            <Link to="#" className="btn btn-primary">See More <i className="mdi mdi-arrow-right"></i></Link>
                        </div>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default KeyFeature;