import React, { useEffect } from 'react'
import SectionTitle from "../../../components/Shared/SectionTitle";
import AOS from "aos";
import "aos/dist/aos.css";
const WhyInofinix = () => {
    useEffect(() => {
        AOS.init({ duraration: 2000 });
        AOS.refresh();
    }, []);
    return (

        <div style={{ marginTop: '-109px' }} data-aos="fade-up">
            <section className="section pb-0 ">
                <div className="container">
                    <div className="justify-content-center row">
                        <div className="text-center">
                            {/* <h6 className="col-12 text-center">We believe in building each other and hence</h6> */}
                            <div className="justify-content-center row">
                                <div className="col-12 text-center col">
                                    <div className="section-title mb-4 pb-2">
                                        <SectionTitle
                                            title="Why Inofinix"
                                            desc="" />
                                        {/* <p className="text-muted para-desc mb-0 mx-auto" name="sectiondesc">Start working with  <span className="text-primary fw-bold">Landrick</span>  that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="mt-4 col-12" >
                            <div id="customer-testi" className="tiny-three-item">
                                <div className="row" >
                                    <div className="mb-1 col-md-4">
                                        <div className="tiny-slide text-center">
                                            <div className="client-testi rounded shadow m-2 p-4"  style={{cursor: 'context-menu'}}>
                                                <h5 className="text-dark text-center">Modern</h5>
                                                <p className="text-muted mt-4">"With years of industry experience, Inofinix team has mastered the art of developing SaaS product with modern tool chain and advanced technology stack."</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-1 col-md-4">
                                        <div className="tiny-slide text-center">
                                            <div className="client-testi rounded shadow m-2 p-4"  style={{cursor: 'context-menu'}}>
                                                <h5 className="text-dark text-center">Cost-effective</h5>
                                                <p className="text-muted mt-4">"While we understand customer needs to develop an awsome product, we also understand constraints and choose the best combination that is efficient and cost effective."</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-1 col-md-4" >
                                        <div className="tiny-slide text-center" style={{display:'flex',height:"100%"}}>
                                            <div className="client-testi rounded shadow m-2 p-4" style={{cursor: 'context-menu'}}>
                                                <h5 className="text-dark text-center">Guaranteed</h5>
                                                <p className="text-muted mt-4">"No matter how challenging the task is, delivery guranteed!. We stay true to our words and commitments"</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="tns-nav">
                                <button type="button" className="tns-nav-active">
                                </button>
                                <button type="button" className="tns-nav-inactive">
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default WhyInofinix
