import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Carousel from 'react-grid-carousel'
import Slider from 'react-slick';
import imagesection1 from '../../../assets/images/imagesection1.jpg';
import avatar from '../../../assets/images/avatar.png';

const Sectionthree = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div style={{marginTop:'-200px'}}>
      <div className="main-content-wrapper cf">
        <div
          className="sqs-layout sqs-grid-12 columns-12"
          id="page-59fca8d46c3194f508016e47"
        >
          <div className="row sqs-row">
            <div className="col sqs-col-12 span-12">
              <div
                className="sqs-block html-block sqs-block-html"
                id="block-yui_3_17_2_87_1509726951338_3735"
              >
                <div
                  className="sqs-block-content "
                  style={{ marginBottom: '100px', marginRight: '116px' }}
                >
                  <h1
                    className="section3heading font-primary text-gray-1000 group mb-[48px] text-[28px] font-bold leading-[36px] md:text-[40px] md:leading-[52px]"
                    style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                  >
                    <b>
                      {' '}
                      Loved by{' '}
                      <span style={{ color: ' rgba(31, 169, 113, 1)' }}>
                        OSS,{' '}
                      </span>
                      trusted by{' '}
                      <span style={{ color: '#2f55d4' }}>Enterprise </span>
                      <br></br>
                    </b>
                  </h1>
                  <div
                    style={{
                      textAlign: 'center',
                      whiteSpace: 'pre-wrap',
                      color: 'grey',
                      fontSize: 'x-large',
                    }}
                    className="section3heading2 mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700"
                  >
                    Inofinix is proud to support developers all around the world
                    <div>
                      by making it easier to build and test modern applications.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="mb-[64px] flex text-center"
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '190px',
          fontSize: '26px',
          fontWeight: 'bold ',
          marginTop: '-23px',
          marginBottom: '45px',
        }}
      >
        <div className="flex-grow">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="font-bold text-gray-1000 text-[28px] leading-[40px] font-primary">
              <b>5.0M+</b>
            </div>
            <div className="font-primary text-gray-700 text-[18px] leading-[28px] font-normal">
              Weekly downloads
            </div>
          </div>
        </div>

        <div className="flex-grow">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="font-bold text-gray-1000 text-[28px] leading-[40px] font-primary">
              <b>42K+</b>
            </div>
            <div className="font-primary text-gray-700 text-[18px] leading-[28px] font-normal">
              GitHub stars
            </div>
          </div>
        </div>
        <div className="flex-grow">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="font-bold text-gray-1000 text-[28px] leading-[40px] font-primary">
              <b>715K+</b>
            </div>
            <div className="font-primary text-gray-700 text-[18px] leading-[28px] font-normal">
              Dependent repositories
            </div>
          </div>
        </div>
      </div>

      {/* <div
        data-cy="tweet-carousel"
        className="swiper w-full swiper-initialized swiper-horizontal"
      >
        <div
          className="swiper-wrapper items-center"
          style={{
            transform: 'translate3d(0px, 0px, 0px)',
            transitionDuration: '0ms',
          }}
        >
          <div
            className="swiper-slide card-marketing-static mb-12 inline-block bg-cover w-[384px] border border-gray-100 !h-[236px] mt-[24px] teal astro-JBIEHTY6 swiper-slide-active"
            // style={{--tealBg: 'url("/images/tweets/teal-card.svg")', --indigoBg: 'url("/images/tweets/indigo-card.svg")', --orangeBg: 'url("/images/tweets/orange-card.svg")', width: '376.333px', marginRight: '32px',
            // data-swiper-slide-index="0"}}
          >
            <div className="mb-[24px] flex gap-x-4 astro-JBIEHTY6">
              <img
                alt="Twitter Avatar of hakimel"
                className="h-[48px] w-[48px] flex-grow-0 rounded-full border-2 border-white shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] astro-JBIEHTY6"
                width="96"
                height="96"
                src="/_astro/hakimel_18Wta4.webp"
                loading="lazy"
                decoding="async"
              />
              <div className="flex-grow astro-JBIEHTY6">
                <span className="font-primary text-gray-1000 text-[18px] font-bold astro-JBIEHTY6">
                  Hakim El Hattab
                </span>
                <br />
                className="astro-JBIEHTY6"
                <a
                  href="https://twitter.com/hakimel"
                  className="text-[14px] text-gray-700 astro-JBIEHTY6"
                >
                  @hakimel
                </a>
              </div>
              <a
                href="https://twitter.com/hakimel/status/1583369424845041664"
                target="_blank"
                rel="noreferrer"
                className="flex-grow-0 astro-JBIEHTY6"
              >
                <span className="sr-only astro-JBIEHTY6">Go to tweet</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="text-teal-500 astro-JBIEHTY6"
                >
                  <path
                    d="M14.355 4.741c.01.142.01.284.01.427 0 4.335-3.299 9.33-9.33 9.33A9.266 9.266 0 0 1 0 13.024c.264.03.518.041.792.041a6.567 6.567 0 0 0 4.07-1.401 3.285 3.285 0 0 1-3.065-2.274c.203.03.406.05.62.05.294 0 .588-.04.862-.111A3.28 3.28 0 0 1 .65 6.112v-.04c.437.243.945.395 1.483.415A3.277 3.277 0 0 1 .67 3.757c0-.61.162-1.168.447-1.655a9.32 9.32 0 0 0 6.761 3.431 3.702 3.702 0 0 1-.081-.751 3.278 3.278 0 0 1 3.28-3.28c.943 0 1.796.397 2.395 1.036a6.457 6.457 0 0 0 2.081-.792 3.27 3.27 0 0 1-1.441 1.807c.66-.07 1.3-.253 1.888-.507a7.048 7.048 0 0 1-1.645 1.695Z"
                    fill="currentColor"
                    className="icon-dark"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="text-[18px] leading-[28px] text-gray-700 line-clamp-4 astro-JBIEHTY6">
              Inofinix makes me feel about 10x as confident in my work. Just look
              at those tests go 🥰
            </div>
          </div>
        </div>
      </div> */}
      {/* <section style={{display:'flex',justifyContent:'center'}}>
        <Carousel
          cols={3}
          rows={1}
          gap={3}
          loop
          hideArrow={true}
          showDots={true} >
          <Carousel.Item>
            <div
              className="card text-white bg-primary mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              <div
                className="card-header"
                style={{ display: 'flex', gap: '10px' }}
              >
                <div>
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                    }}
                    // className="img-fluid"
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <b>Cristiano</b>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="card text-white bg-primary mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              <div
                className="card-header"
                style={{ display: 'flex', gap: '10px' }}
              >
                <div>
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                    }}
                    // className="img-fluid"
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <b>Lionel</b>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="card text-white bg-primary mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              <div
                className="card-header"
                style={{ display: 'flex', gap: '10px' }}
              >
                <div>
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                    }}
                    // className="img-fluid"
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <b>Mbappe</b>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            {' '}
            <div
              className="card text-white bg-primary mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              <div
                className="card-header"
                style={{ display: 'flex', gap: '10px' }}
              >
                <div>
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                    }}
                    // className="img-fluid"
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <b>Henrik Joreteg</b>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            {' '}
            <div
              className="card text-white bg-primary mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              <div
                className="card-header"
                style={{ display: 'flex', gap: '10px' }}
              >
                <div>
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                    }}
                    // className="img-fluid"
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <b>Henrik Joreteg</b>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            {' '}
            <div
              className="card text-white bg-primary mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              <div
                className="card-header"
                style={{ display: 'flex', gap: '10px' }}
              >
                <div>
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                    }}
                    // className="img-fluid"
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <b>Mahendra Singh</b>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="card text-white bg-primary mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              <div
                className="card-header"
                style={{ display: 'flex', gap: '10px' }}
              >
                <div>
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                    }}
                    // className="img-fluid"
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <b>William Kane</b>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="card text-white bg-primary mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              <div
                className="card-header"
                style={{ display: 'flex', gap: '10px' }}
              >
                <div>
                  <img
                    src={avatar}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                    }}
                    // className="img-fluid"
                    alt=""
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <b>Henrik Joreteg</b>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Primary card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div> */}
            {/* anything you want to show in the grid */}
          {/* </Carousel.Item> */}
          {/* ... */}
        {/* </Carousel> */}
      {/* </section> */}
    </div>
  );
};

export default Sectionthree;
