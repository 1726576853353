import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import ReviewsSlider from "../../../components/Shared/ReviewsSlider";
import SectionTitle from "../../../components/Shared/SectionTitle";
import { reviews } from "../../../common/data";

const WorkProcess = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
    return (
      <React.Fragment>
         <Container className="mt-100 mt-60" data-aos='fade-up'>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2" style={{margin: "-109px"}}>
                <h6 className="text-primary">Work Process</h6>
                <h4 className="title mb-4">How do we work ?</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  We have a simple, Transparent and customisable process that works!
                </p>  
              </div>
            </Col>
          </Row>

          <Row data-aos='fade-up'>
            <Col md={4} className="mt-4 pt-2">
            <h5 className="text-dark text-center">Discussion</h5>
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-presentation-edit d-block rounded  h3 mb-0"></i>
                </div>

                <CardBody>
                  <p className="text-muted mb-0">
                    Discussions with key stakeholders combined with our industry experience helps to understand the business needs.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
            <h5 className="text-dark text-center">Research and Analysis</h5>
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-airplay d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <p className="text-muted mb-0">
                    Identify the key objectives the project/product needs to achieve and do recearch and required preparation. 
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
            <h5 className="text-dark text-center">Define & Design</h5>
              <Card className="features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-image-check d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <p className="text-muted mb-0">
                    Define goals, user stories, prioritised requirements, wireframes, mockups and end to end design and architecture concept.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center" data-aos='fade-up'>
            <Col md={4} className="mt-4 pt-2">
            <h5 className="text-dark text-center">Implement & Test</h5>
              <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-create-dashboard d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <p className="text-muted mb-0">
                    Convert design into working product. We have a customised process to iterate efficiently to achieve faster go-to-market.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
            <h5 className="text-dark text-center">Delivery</h5>
              <Card className="features feature-primary feature-clean work-process bg-transparent  text-center">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-presentation-line d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <p className="text-muted mb-0">
                    Product is delivered and ensured smoother go-live and transition.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );  
};
export default WorkProcess;
