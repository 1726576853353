import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  
  Row,
 
} from "reactstrap";


//Import Images

import aboutus from "./Images/aboutus.jpg";



import ExperienceTeam from "../../CorporateBusiness/ExperienceTeam";
import MindPower from "../NFTMarketPlace/MindPower";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import AOS from "aos";
import "aos/dist/aos.css";
import Popup from "../../../components/Layout/popup";


export default class PageAboutusTwo extends Component {


  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
    });
    AOS.init({duration:2000});
    AOS.refresh();

    document.getElementById("topnav").classList.add("bg-secondary-x");

    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null){
    if (top > 80) {
      // document.querySelector(".shoppingbtn").classList.remove("btn-light");
      // document.querySelector(".shoppingbtn").classList.add("btn-primary");
      document.getElementById("topnav").classList.add("nav-sticky");
      document.getElementById("topnav").classList.remove("bg-secondary-x");
    } else {
      // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
      // document.querySelector(".shoppingbtn").classList.add("btn-light");
      document.getElementById("topnav").classList.remove("nav-sticky");
      document.getElementById("topnav").classList.add("bg-secondary-x");
    }
  }
  };
  render() {
    return (
      <React.Fragment>
        {/* <section
          className="bg-half-170 d-table w-100"
          style={{ background: `url(${aboutus})` }}
        >
          <div className="bg-overlay"></div>
          <Container>
            <Row className="mt-5 justify-content-center" data-aos='fade-up'>
              <Col lg={12} className="text-center">
                <div className="pages-heading title-heading">
                  <h2 className="text-white title-dark"> About us </h2> */}
                  {/* <p className="text-white-50 para-desc mb-0 mx-auto">Start working with Inofinix that can provide everything you need to generate awareness, drive traffic, connect.</p> */}
                {/* </div>
              </Col>
            </Row>
          </Container>
        </section> */}

        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section bg-light">
        <Section1 />
        </section>
        <Section2 />
        <section className="section bg-light">
         <Section3 />
         </section>
        <Section4 />
{/*         <section className="section bg-light">
        <MindPower />
        </section> */}
        <Popup />
          
       {/* <ExperienceTeam /> */}
      </React.Fragment>
    );
  }
}
