import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Popup from '../../../components/Layout/popup';

//import Images
import homebg from "../../../assets/images/nft/home.png";
import startup from "../../../assets/images/nft/startup.png";
import AOS from 'aos';
import "aos/dist/aos.css";

const images = [homebg, startup];
const Section = () => {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        AOS.init({ duration: 1000 });
        AOS.refresh();
    }, []);

    useEffect(() => {
        const timerId = setInterval(() => {
            if (currentImage < 1) {
                setCurrentImage(currentImage => currentImage + 1);
            }
            else {
                setCurrentImage(0);
            }
        }, 5000);

        return () => clearInterval(timerId);
    }, [currentImage]);

    return (
        <React.Fragment >
            <section className="bg-home d-flex align-items-center background-effect bg-soft-primary" id="home">
                <Container data-aos='fade-up' className="container z-index-1">
                    <Row className="align-items-center">
                        <Col lg={6} md={6} xs={12}>
                            {currentImage === 0 ? (
                                <div key={currentImage} className="fadeText1">
                                    <div className="title-heading">
                                        <h4 className="display-5 fw-bold text-dark mb-4">Accelerate your Growth </h4>
                                        <h6 className="display-6 fw-bold text-dark mb-4" style={{fontSize:'30px'}}>using INOFINIX Solutions & Services</h6>
                                    </div>
                                </div>
                            ) : (
                                <div key={currentImage} className="fadeText2">
                                    <div className="title-heading">
                                    <h4 className="display-5 fw-bold text-dark mb-4">Transform your Business</h4>
                                    <h6 className="display-6 fw-bold text-dark mb-4" style={{fontSize:'30px'}}>using INOFINIX Solutions & Services</h6>
                                    </div>
                                </div>
                            )}
                            <p className="para-desc  text-muted mt-0"> Benefit from our expertise on designing, developing & managing high quality IT platforms and apps.</p>
                            <div className="responsivebutton">
                                <Link to="/contact" className="btn btn-primary me-1"><i className="uil uil-bitcoin"></i> Contact us</Link>{" "}
                                <Link to="/about" className="btn btn-soft-primary aboutusresp"><i className="uil uil-file-bookmark-alt"></i> About us</Link>
                            </div>
                        </Col>
                        <Col lg={6} md={6} xs={12} className="imgResp mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <img src={images[currentImage]} className="imgTrans" key={currentImage} />
                        </Col>
                    </Row>
                </Container>

                <ul className="circles p-0 mb-0">
                    <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
                </ul>
            </section>

            <Popup />
        </React.Fragment>
    );
}

export default Section;