import React from 'react';
import { Redirect } from "react-router-dom";

//Main Index

//Special





//Auth Pages












// Import all components




//Docs

//Components

import PageAboutusTwo from "../pages/Pages/AboutPage/PageAboutusTwo";


import PageServices from "../pages/Pages/PageServices";
import CorporateBusiness from "../pages/CorporateBusiness";
import PageTerms from "../pages/Pages/Utility/PageTerms";
import PagePrivacy from "../pages/Pages/Utility/PagePrivacy";
//Account

//Blog

//Case Study

//course-detail 

//Utility

//Contact
import PageContactOne from "../pages/Pages/Contact/PageContactOne";

// forums

//Email

//Help Center

//Demo Shop

//Corporate Buisness

//Demo Portfolio


//Career
import Job from "../pages/Pages/DemoJob/Job";
import PageJobDetail from "../pages/Pages/DemoJob/PageJobDetail";

//Cryptocurrency




//Demo Blog

//nft marketplace
import Nftmarketplace from "../pages/Pages/NFTMarketPlace/index";
import PageBlog from '../pages/Pages/Blog/PageBlog'
import PageBlogDetail from '../pages/Pages/Blog/PageBlogDetail'
import PageJobApply from '../pages/Pages/DemoJob/PageJobApply';
import SaasOnepage from '../pages/Saas Onepage/index';
import ITSolution from '../pages/ITSolution/index';
import { CookiePage } from '../pages/Pages';
import Product from '../pages/Pages/product/Product';
import bookdemo from '../pages/Pages/BOOKDEMO/Bookdemo';
import PageMembers from '../pages/Pages/Account/PageMembers';
import BlogAbout from '../pages/Pages/DemoBlog/BlogAbout';
import BlogAboutPradeep from '../pages/Pages/DemoBlog/BlogAboutPrdeep';
import BlogAboutDarshan from '../pages/Pages/DemoBlog/BlogAboutDarshan';
import BlogAboutVijay from '../pages/Pages/DemoBlog/BlogAboutVijay';
import BlogAboutGmahesh from '../pages/Pages/DemoBlog/BlogAboutGmahesh';
import BlogAboutAkash from '../pages/Pages/DemoBlog/BlogAboutAkash';
import BlogAboutGauthami from '../pages/Pages/DemoBlog/BlogAboutGauthami';
import BlogAboutMegha from '../pages/Pages/DemoBlog/BlogAboutMegha';
import BlogAboutPooja from '../pages/Pages/DemoBlog/BlogAboutPooja';
import BlogAboutSonali from '../pages/Pages/DemoBlog/BlogAboutSonali';
import BlogAboutShreyas from '../pages/Pages/DemoBlog/BlogAboutShreyas';
import BlogAboutPavitra from '../pages/Pages/DemoBlog/BlogAboutPavitra';



//CloudHosting

//Forums

//Footer layouts 


const routes = [
  //routes without Layout
  // { path: "/index-forums", component: Forums, isTopbarDark: true, isWithoutLayout: true },

  // //CloudHosting
  // { path: "/index-hosting", component: CloudHosting, isWithoutLayout: true },
  // { path: "/hosting-domain", component: HostingDomain, isWithoutLayout: true },
  // { path: "/hosting-shared", component: HostingShared, isWithoutLayout: true },
  // { path: "/hosting-vps", component: HostingVps, isWithoutLayout: true },
  // { path: "/hosting-dedicated", component: HostingDedicated, isWithoutLayout: true },
  // { path: "/hosting-cloud", component: HostingCloud, isWithoutLayout: true },
  // { path: "/hosting-reseller", component: HostingReseller, isWithoutLayout: true },
  // { path: "/hosting-aboutus", component: HostingAboutus, isWithoutLayout: true },
  // { path: "/hosting-services", component: HostingServices, isWithoutLayout: true },
  // { path: "/hosting-faqs", component: HostingFaqs, isWithoutLayout: true },
  // { path: "/hosting-blog", component: HostingBlog, isWithoutLayout: true },
  // { path: "/hosting-blog-detail", component: HostingBlogDetail, isWithoutLayout: true },

  // //Cryptocurrency
  // { path: "/index-crypto", component: Cryptocurrency, isWithoutLayout: true },
  // { path: "/crypto-about", component: CryptoAbout, isWithoutLayout: true },
  // { path: "/crypto-market", component: CryptoMarket, isWithoutLayout: true },
  // { path: "/crypto-token", component: CryptoToken, isWithoutLayout: true },
  // { path: "/crypto-services", component: CryptoServices, isWithoutLayout: true },
  // { path: "/crypto-faqs", component: CryptoFaqs, isWithoutLayout: true },
  // { path: "/crypto-whitepaper", component: CryptoWhitepaper, isWithoutLayout: true },
  // { path: "/crypto-blog", compSir,I want to go early in evening.
  // //Demo Blog 
  // { path: "/index-blog", component: Blog, isWithoutLayout: true },
  { path: "/blog-about", component: BlogAbout, isWithoutLayout: true },
  { path: "/blog-about/Pradeep-Rai", component: BlogAboutPradeep, isWithoutLayout: true },
  { path: "/blog-about/Darshan-HS", component: BlogAboutDarshan, isWithoutLayout: true },
  { path: "/blog-about/Vijay-Patil", component: BlogAboutVijay, isWithoutLayout: true },
  { path: "/blog-about/G-Mahesh", component: BlogAboutGmahesh, isWithoutLayout: true },
  { path: "/blog-about/Akash-Patil", component: BlogAboutAkash, isWithoutLayout: true },
  { path: "/blog-about/Gauthami-BG", component: BlogAboutGauthami, isWithoutLayout: true },
  { path: "/blog-about/Megha-Jadhav", component: BlogAboutMegha, isWithoutLayout: true },
  { path: "/blog-about/Pooja-Krishna", component: BlogAboutPooja, isWithoutLayout: true },
  { path: "/blog-about/Sonali-Misal", component: BlogAboutSonali, isWithoutLayout: true },
  { path: "/blog-about/Shreyas", component: BlogAboutShreyas, isWithoutLayout: true },
  { path: "/blog-about/Pavitra-K", component: BlogAboutPavitra, isWithoutLayout: true },
  // { path: "/blog-slider-post", component: BlogSliderPost, isWithoutLayout: true },
  // { path: "/blog-gallery-post", component: BlogGalleryPost, isWithoutLayout: true },
  // { path: "/blog-youtube-post", component: BlogYoutubePost, isWithoutLayout: true },
  // { path: "/blog-vimeo-post", component: BlogVimeoPost, isWithoutLayout: true },
  // { path: "/blog-audio-post", component: BlogAudioPost, isWithoutLayout: true },
  // { path: "/blog-blockquote-post", component: BlogBlockquotePost, isWithoutLayout: true },
  // { path: "/blog-left-sidebar-post", component: BlogLeftSliderPost, isWithoutLayout: true },

  // //nft marketplace
  // { path: "/index-nft", component: Nftmarketplace, isWithoutLayout: true },
  // { path: "/nft-explore", component: NftExplore, isWithoutLayout: true },
  // { path: "/nft-creators", component: NftCreators, isWithoutLayout: true },
  // { path: "/nft-item-detail", component: NftItemDetail, isWithoutLayout: true },

  // //Shop page
  // { path: "/index-shop", component: Shop, isWithoutLayout: true },
  // { path: "/shop-aboutus", component: ShopAboutus, isWithoutLayout: true },
  // { path: "/shop-fullwidth-grids", component: ShopFullwidthGrids, isWithoutLayout: true },
  // { path: "/shop-grids", component: ShopGrid, isWithoutLayout: true },
  // { path: "/shop-fullwidth-lists", component: ShopFullwidthLists, isWithoutLayout: true },
  // { path: "/shop-lists", component: ShopLists, isWithoutLayout: true },
  // { path: "/shop-product-detail", component: ShopProductDetail, isWithoutLayout: true },
  // { path: "/shop-cart", component: ShopCart, isWithoutLayout: true },
  // { path: "/shop-checkouts", component: ShopCheckouts, isWithoutLayout: true },
  // { path: "/shop-myaccount", component: ShopMyAccount, isWithoutLayout: true },
  // { path: "/shop-blog", component: ShopBlog, isWithoutLayout: true },
  // { path: "/shop-blog-detail", component: ShopBlogDetails, isWithoutLayout: true },


  // //Help Center
  // { path: "/helpcenter-overview", component: HelpCenterOverview, isWithoutLayout: true },
  // { path: "/helpcenter-faqs", component: HelpCenterFaqs, isWithoutLayout: true },
  // { path: "/helpcenter-guides", component: HelpCenterGuides, isWithoutLayout: true },
  // { path: "/helpcenter-support-request", component: HelpCenterSupportRequest, isWithoutLayout: true },

  // //PortFolio
  // { path: "/index-portfolio", component: Portfolio, isWithoutLayout: true },
  // { path: "/portfolio-modern-two", component: PageWorkModernTwo, isWithoutLayout: true },
  // { path: "/portfolio-modern-three", component: PageWorkModernThree, isWithoutLayout: true },
  // { path: "/portfolio-modern-four", component: PageWorkModernFour, isWithoutLayout: true },
  // { path: "/portfolio-modern-five", component: PageWorkModernFive, isWithoutLayout: true },
  // { path: "/portfolio-modern-six", component: PageWorkModernSix, isWithoutLayout: true },
  // { path: "/portfolio-classic-two", component: PageWorkClassicTwo, isWithoutLayout: true },
  // { path: "/portfolio-classic-three", component: PageWorkClassicThree, isWithoutLayout: true },
  // { path: "/portfolio-classic-four", component: PageWorkClassicFour, isWithoutLayout: true },
  // { path: "/portfolio-classic-five", component: PageWorkClassicFive, isWithoutLayout: true },
  // { path: "/portfolio-classic-six", component: PageWorkClassicSix, isWithoutLayout: true },

  // { path: "/portfolio-grid-two", component: PageWorkGridTwo, isWithoutLayout: true },
  // { path: "/portfolio-grid-three", component: PageWorkGridThree, isWithoutLayout: true },
  // { path: "/portfolio-grid-four", component: PageWorkGridFour, isWithoutLayout: true },
  // { path: "/portfolio-grid-five", component: PageWorkGridFive, isWithoutLayout: true },
  // { path: "/portfolio-grid-six", component: PageWorkGridSix, isWithoutLayout: true },

  // { path: "/portfolio-masonry-two", component: PageWorkMasonryTwo, isTopbarDark: true },

  // { path: "/portfolio-detail-one", component: PageWorkDetailOne, isWithoutLayout: true },
  // { path: "/portfolio-detail-two", component: PageWorkDetailTwo, isWithoutLayout: true },
  // { path: "/portfolio-detail-three", component: PageWorkDetailThree, isWithoutLayout: true },
  // { path: "/portfolio-detail-four", component: PageWorkDetailFour, isWithoutLayout: true },

  // { path: "/portfolio-about", component: PortfolioAbout, isWithoutLayout: true },
  // { path: "/portfolio-service", component: PortfolioService, isWithoutLayout: true },

  // { path: "/portfolio-page-masonry-two", component: PageMasonryTwo, isWithoutLayout: true },
  // { path: "/portfolio-masonry-three", component: PageMasonryThree, isWithoutLayout: true },
  // { path: "/portfolio-masonry-Four", component: PageMasonryFour, isWithoutLayout: true },
  // { path: "/portfolio-masonry-Five", component: PageMasonryFive, isWithoutLayout: true },
  // { path: "/portfolio-masonry-Six", component: PageMasonrySix, isWithoutLayout: true },

  // //Contact without layout
  // {
  //   path: "/page-contact-detail",
  //   component: PageContactDetail,
  //   isWithoutLayout: true,
  //   isTopbarDark: true
  // },

  // //Email Pages
  // { path: "/email-alert", component: EmailAlert, isWithoutLayout: true },
  // {
  //   path: "/email-password-reset",
  //   component: EmailPasswordReset,
  //   isWithoutLayout: true,
  // },
  // {
  //   path: "/email-confirmation",
  //   component: EmailConfirmation,
  //   isWithoutLayout: true,
  // },
  // { path: "/email-invoice", component: EmailInvoice, isWithoutLayout: true },

  // //Special Pages
  // {
  //   path: "/page-comingsoon",
  //   component: PageComingSoon,
  //   isWithoutLayout: true,
  // },
  // {
  //   path: "/page-comingsoon2",
  //   component: PageComingSoon2,
  //   isWithoutLayout: true,
  // },
  // { path: "/page-error", component: PageError, isWithoutLayout: true },
  // { path: "/page-thankyou", component: PageThankYou, isWithoutLayout: true },
  // {
  //   path: "/page-maintenance",
  //   component: PageMaintenance,
  //   isWithoutLayout: true,
  // },

  //Demos Corporate Business
  // { path: "/work", component: CorporateBusiness, isWithoutLayout: true,isTopbarDark: true },
  // { path: "/corporate-about", component: CorporateAbout, isWithoutLayout: true },
  // { path: "/corporate-services", component: CorporateServices, isWithoutLayout: true },
  // { path: "/corporate-team", component: CorporateTeam, isWithoutLayout: true },
  // { path: "/corporate-pricing", component: CorporatePricing, isWithoutLayout: true },
  // { path: "/corporate-blog", component: CorporateBlog, isWithoutLayout: true },
  // { path: "/corporate-blog-detail", component: CorporateBlogDetail, isWithoutLayout: true },

  //Page Job
  // { path: "/index-job", component: Job, isWithoutLayout: true },
  // { path: "/careers", component: Job, isWithoutLayout: true,isTopbarDark: true  },
  // { path:  "/https://in.indeed.com/cmp/Inofinx-Pvt-Ltd/jobs", component: PageJobDetail, isWithoutLayout: true  },

  // { path: "/job-list-one", component: JobListone, isWithoutLayout: true },
  // { path: "/job-list-two", component: JobListTwo, isWithoutLayout: true },
  // { path: "/job-list-three", component: JobListThree, isWithoutLayout: true },
  // { path: "/job-list-four", component: JobListFour, isWithoutLayout: true },
  // { path: "/job-list-five", component: JobListFive, isWithoutLayout: true },
  // // { path: "/job-detail-one", component: PageJobDetail, isWithoutLayout: true },
  // { path: "/job-detail-two", component: PageJobDetailTwo, isWithoutLayout: true },
  // { path: "/job-detail-three", component: PageJobDetailThree, isWithoutLayout: true },
  { path: "/job-apply", component: PageJobApply, isWithoutLayout: true },
  { path: "/cookies-and-policies", component: CookiePage, isWithoutLayout: true },

  // { path: "/job-candidate-list", component: PageCandidateList, isWithoutLayout: true },
  // { path: "/job-candidate", component: PageJobCandidate, isWithoutLayout: true },
  // { path: "/job-company-list", component: PageCompanyList, isWithoutLayout: true },
  // { path: "/job-company", component: PageJobCompany, isWithoutLayout: true },
  // { path: "/job-about", component: JobAbout, isWithoutLayout: true },
  // { path: "/job-price", component: JobPrice, isWithoutLayout: true },
  // { path: "/job-faqs", component: JobFaq, isWithoutLayout: true },
  // { path: "/auth-bs-login", component: AuthLogin, isWithoutLayout: true },
  // { path: "/auth-bs-signup", component: AuthSignup, isWithoutLayout: true },
  // { path: "/auth-bs-reset", component: AuthReset, isWithoutLayout: true },

  // { path: "/page-job", component: PageJob, isWithoutLayout: true },
  // { path: "/page-jobs-sidebar", component: PageJobsSidebar, isWithoutLayout: true },

  // //User Pages
  // { path: "/auth-login", component: PageLogin, isWithoutLayout: true },
  // {
  //   path: "/auth-cover-login",
  //   component: PageCoverLogin,
  //   isWithoutLayout: true,
  // },
  // {
  //   path: "/auth-login-three",
  //   component: PageLoginThree,
  //   isWithoutLayout: true,
  // },

  // {
  //   path: "/auth-login-bg-video",
  //   component: PageLoginFive,
  //   isWithoutLayout: true,
  // },

  // { path: "/auth-signup", component: PageSignup, isWithoutLayout: true },
  // {
  //   path: "/auth-cover-signup",
  //   component: PageCoverSignup,
  //   isWithoutLayout: true,
  // },
  // {
  //   path: "/auth-signup-three",
  //   component: PageSignupThree,
  //   isWithoutLayout: true,
  // },

  // {
  //   path: "/auth-signup-bg-video",
  //   component: PageSignupFive,
  //   isWithoutLayout: true,
  // },

  // {
  //   path: "/auth-re-password",
  //   component: PageRecoveryPassword,
  //   isWithoutLayout: true,
  // },
  // {
  //   path: "/auth-cover-re-password",
  //   component: PageCoverRePassword,
  //   isWithoutLayout: true,
  // },
  // {
  //   path: "/auth-re-password-three",
  //   component: PageRePasswordThree,
  //   isWithoutLayout: true,
  // },

  // {
  //   path: "/auth-reset-password-bg-video",
  //   component: PageResetPassFive,
  //   isWithoutLayout: true,
  // },

  // { path: "/footer-layouts", component: PageFooterLayouts },

  // Landings
  { path: "/index-onepage", component: SaasOnepage, isTopbarDark: true },
  // { path: "/index-customer", component: Customer, isTopbarDark: true },

  // { path: "/index-software", component: Software },
  // { path: "/index-payments", component: Payments, isTopbarDark: true },
  // { path: "/index-car-riding", component: IndexCarRiding, isTopbarDark: true },
  // { path: "/index-classic-saas", component: IndexclassicSaas, isTopbarDark: true },
  // { path: "/index-classic-app", component: ClassicApp, isTopbarDark: true },
  // { path: "/index-saas", component: Saas, isTopbarDark: true },
  // { path: "/index-apps", component: Apps, isTopbarDark: true },
  // { path: "/index-agency", component: Agency, isTopbarDark: true },
  // { path: "/index-studio", component: Studio, isTopbarDark: true },
  // { path: "/index-business", component: Business, isTopbarDark: true },
  { path: "/index-saas-development", component: ITSolution },
  { path: "/product", component: Product, isTopbarDark: true },
  // { path: "/index-marketing", component: Marketing, isTopbarDark: true },
  // { path: "/index-hotel", component: Hotel, isTopbarDark: true },
  // { path: "/index-developer", component: Developer, isTopbarDark: true },
  // { path: "/index-landing-four", component: LandingFour, isTopbarDark: true },
  // { path: "/index-integration", component: Integration },
  // { path: "/index-task-management", component: TaskManagement },
  // { path: "/index-hospital", component: Hospital, isTopbarDark: true },
  // { path: "/index-construction", component: Construction },
  // { path: "/index-email-inbox", component: EmailInbox, isTopbarDark: true },
  // { path: "/index-landing-one", component: LandingOne },
  // { path: "/index-landing-two", component: LandingTwo, isTopbarDark: true },
  // { path: "/index-landing-three", component: LandingThree },
  // { path: "/index-travel", component: Travel },
  // { path: "/index-real-estate", component: RealEstate },
  // { path: "/index-seo-agency", component: SeoAgency, isTopbarDark: true },
  // { path: "/index-modern-business", component: ModernBusiness },
  // { path: "/index-coworking", component: Coworking },
  // { path: "/index-event", component: Event },
  // { path: "/index-course", component: Course },
  // { path: "/index-personal", component: Personal, isTopbarDark: true },
  // { path: "/index-single-product", component: SingleProduct, isTopbarDark: true },
  // { path: "/index-enterprise", component: Enterprise },
  // { path: "/index-services", component: Services, isTopbarDark: true },
  // { path: "/index-insurance", component: Insurance },
  // { path: "/index-ebook", component: Ebook, isTopbarDark: true },
  // { path: "/index-social-marketing", component: SocialMarketing, isTopbarDark: true },
  // { path: "/index-digital-agency", component: DigitalAgency, isTopbarDark: true },
  // { path: "/index-online-learning", component: OnlineLearning, isTopbarDark: true },
  // { path: "/index-finance", component: Finance, isTopbarDark: true },
  // { path: "/index-logistics", component: Logistics, isTopbarDark: false },
  // { path: "/index-videocall", component: VideoCall, isTopbarDark: true },
  // { path: "/index-it-solution-two", component: iTSolutionTwo },
  // { path: "/index-freelancer", component: Freelancer, isTopbarDark: true },
  // { path: "/index-blockchain", component: Blockchain },
  // { path: "/index-crypto-two", component: CryptoTwo },

  // { path: "/page-aboutus", component: PageAboutUs, isTopbarDark: true },
  // { path: "/page-aboutus-two", component: PageAboutusTwo },
  { path: "/about", component: PageAboutusTwo, isTopbarDark: true },
  // { path: "/page-history", component: PageHistory, isTopbarDark: true },
  // { path: "/page-pricing", component: PagePricing, isTopbarDark: true },
  // { path: "/page-services", component: PageServices, isTopbarDark: true },
  { path: "/services", component: PageServices, isTopbarDark: true },
  // { path: "/product", component: Product, isTopbarDark: true },
  // { path: "/page-team", component: PageTeam, isTopbarDark: true },

  //Utility
  { path: "/terms-of-use", component: PageTerms, isTopbarDark: true },
  { path: "/privacy-policy", component: PagePrivacy, isTopbarDark: true },

  //Page Profile
  // { path: "/page-profile", component: PageProfile },
  { path: "/page-members", component: PageMembers },
  // { path: "/page-works", component: PageWorks },
  // { path: "/page-messages", component: PageMessages },
  // { path: "/page-profile-edit", component: PageProfileEdit },
  // { path: "/page-payments", component: PagePayments },
  // { path: "/page-invoice", component: PageInvoice, isTopbarDark: true },
  // { path: "/page-chat", component: PageChat },

  // //Page Blog
  { path: "/book-a-demo", component: bookdemo, isTopbarDark: true },

  { path: "/blog", component: PageBlog, isTopbarDark: true },
  { path: "/blog/blog-1", component: PageBlogDetail, isTopbarDark: true },
  // { path: "/page-blog-detail-two", component: PageBlogDetailTwo },
  // { path: "/page-blog-sidebar", component: PageBlogSidebar, isTopbarDark: true },
  // { path: "/page-blog-list", component: PageBlogList, isTopbarDark: true },
  // { path: "/page-blog-list-sidebar", component: PageBlogListSidebar, isTopbarDark: true },

  // //Page Case Study
  // { path: "/page-all-cases", component: AllCases },
  // { path: "/page-case-detail", component: CaseDetail, isTopbarDark: true },

  // //course-detail
  // { path: "/page-course-detail", component: CourseDetail },

  //Page Contact
  // { path: "/page-contact-one", component: PageContactOne, isTopbarDark: true },
  { path: "/contact", component: PageContactOne, isTopbarDark: true },
  // { path: "/page-contact-three", component: PageContactThree, isTopbarDark: true },
  // { path: "/page-contact-two", component: PageContactTwo, isTopbarDark: true },

  // // forums
  // { path: "/forums", component: Overview, isTopbarDark: true },
  // { path: "/forums-topic", component: ForumTopic, isTopbarDark: true },
  // { path: "/forums-comments", component: ForumsComments, isTopbarDark: true },

  //Components
  // { path: "/ui-button", component: Buttons, isTopbarDark: true },
  // { path: "/ui-badges", component: Badges, isTopbarDark: true },
  // { path: "/ui-alert", component: Alerts, isTopbarDark: true },
  // { path: "/ui-dropdown", component: Dropdowns, isTopbarDark: true },
  // { path: "/ui-typography", component: Typography, isTopbarDark: true },
  // { path: "/ui-background", component: Background, isTopbarDark: true },
  // { path: "/ui-text", component: TextColors, isTopbarDark: true },
  // { path: "/ui-accordion", component: Accordions, isTopbarDark: true },
  // { path: "/ui-shadow", component: BoxShadows, isTopbarDark: true },
  // { path: "/ui-border", component: Borders, isTopbarDark: true },
  // { path: "/ui-carousel", component: Carousel, isTopbarDark: true },
  // { path: "/ui-lightbox", component: Lightbox, isTopbarDark: true },
  // { path: "/ui-tooltip-popover", component: Tooltips, isTopbarDark: true },
  // { path: "/ui-card", component: Cards, isTopbarDark: true },
  // { path: "/ui-form", component: Forms, isTopbarDark: true },
  // { path: "/ui-breadcrumb", component: Breadcrumbs, isTopbarDark: true },
  // { path: "/ui-Pagination", component: Paginations, isTopbarDark: true },
  // { path: "/ui-avatar", component: Avatar, isTopbarDark: true },
  // { path: "/ui-nav-tabs", component: NavTabs, isTopbarDark: true },
  // { path: "/ui-modals", component: Modals, isTopbarDark: true },
  // { path: "/ui-tables", component: Tables, isTopbarDark: true },
  // { path: "/ui-icons", component: Icons, isTopbarDark: true },
  // { path: "/ui-progressbar", component: Progressbar, isTopbarDark: true },

  //Docs
  // { path: "/changelog", component: ChangeLog, isTopbarDark: true },
  // { path: "/documentation", component: Documentation, isTopbarDark: true },
  // { path: "/widget", component: Widget, isTopbarDark: true },

  //Index Main
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/index" />,
  },
  { path: "/index", component: Nftmarketplace, isIndexPage: true },
  // { path: "/index", component: Main, isTopbarDark: true },

];

export default routes;
