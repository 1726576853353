import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../../../components/Shared/SectionTitle";
import FeaturedJobBox from "./FeaturedJobBox";

//Import images
import img1 from "../../../../assets/images/job/nodejs2.svg";
import img2 from "../../../../assets/images/job/golangicon.svg";
import img3 from "../../../../assets/images/job/Php6.svg";
import img4 from "../../../../assets/images/job/reactjs2.svg";
import img5 from "../../../../assets/images/job/Gitlab.svg";
import img6 from "../../../../assets/images/job/Mg.svg";
import img7 from "../../../../assets/images/job/Sketch.svg";
import img8 from "../../../../assets/images/job/Twilio.svg";
import img9 from "../../../../assets/images/job/Udemy.svg";
import img10 from "../../../../assets/images/job/Webhooks.svg";
import img11 from "../../../../assets/images/job/Appdynamics.svg";
import img12 from "../../../../assets/images/job/Gradle.svg";


class FeaturedJobs extends Component {
  state = {
    jobs: [
      {
        id: 1,
        image: img1,
        title: "Node.js Developer ",
        city: "Bangalore",
        country: "India",
        type:"jk=00c8d37451b30d99&start=0"
      },
      {
        id: 2,
        image: img2,
        title: "Golang Developer",
        city: "Bangalore",
        country: "India",
        type:"jk=ad72fd1f3719137f&start=0"
      },
      {
        id: 3,
        image: img3,
        title: "PHP Developer",
        city: "Bangalore",
        country: "India",
        type:"jk=9d197b53c8d29d59&start=0"
      },
      {
        id: 4,
        image: img4,
        title: "ReactJS Developer",
        city: "Bangalore",
        country: "India",
        type:"jk=0ee6556e8f73ffc0&start=0"
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60" data-aos='fade-up'>
          {/* section title */}
          <SectionTitle
            title="Featured Jobs"
            desc=" Checkout our open positions and apply today!"
          />

          <Row data-aos='fade-up'>
            {/* Featured Job Box */}
             <FeaturedJobBox jobs={this.state.jobs} />

            <Col xs="12" className="text-center mt-4 pt-2">
              <Link to={{ pathname: "https://in.indeed.com/cmp/Inofinx-Pvt-Ltd/jobs" }} target="_blank" className="btn btn-primary">
                See All Jobs <i className="uil uil-angle-right-b"></i>
              </Link>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default FeaturedJobs;
