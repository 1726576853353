// React Basic and Bootstrap
import React, { Component } from 'react';
import Popup from '../../../components/Layout/popup';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import book from '../../../assets/images/client/book.jpg';
import AOS from "aos";
import "aos/dist/aos.css";

import { InlineWidget } from "react-calendly";
//Import Images





class Bookdemo extends Component {
    constructor(props) {
        super(props);
    }



    componentDidMount() {
        document.body.classNameList = "";
        // document.querySelector(".shoppingbtn").classNameList.add("btn-primary");
        document.getElementById("topnav").classList.add("bg-secondary-x");
        window.addEventListener("scroll", this.scrollNavigation, true);
        AOS.init({ duration: 2000 });
        AOS.refresh();



        // document.querySelector(".cookie-popup").classNameList.remove("show");   
    }



    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        const navBar = document.getElementById("topnav");
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (navBar != null) {
            if (top > 80) {
                navBar.classList.add("nav-sticky");
                document.getElementById("topnav").classList.remove("bg-secondary-x");
            } else {
                navBar.classList.remove("nav-sticky");
                document.getElementById("topnav").classList.add("bg-secondary-x");
                // document.querySelector(".shoppingbtn").classNameList.add("btn-primary");
                // document.querySelector(".shoppingbtn").classNameList.remove("btn-light");
                // document.querySelector(".settingbtn").classNameList.add("btn-soft-primary");
            }
        }
    };


    render() {
        return (
            <React.Fragment>
                {/* breadcrumb */}
                {/* <section className="bg-half-170 bg-light d-table w-100"
                    style={{ background: `url(${book})` }}
                >
                    <div className="bg-overlay"></div>
                    <Container >
                        <Row className="mt-5 justify-content-center" data-aos='fade-up'>
                            <Col lg={12} className="text-center">
                                <div className="pages-heading">
                                    <h2 className="text-white title-dark"> Book-a-Meeting </h2>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}

                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                <section style={{ marginTop: '-28px' }}>
                    <div className="text-center mt-0 mt-md-5 pt-0 pt-md-5 col-lg-9" style={{display:'initial'}}>
                        <div className="title-heading margin-top-100">
                            <h1 className="heading mb-4">Schedule your meeting with <span className="text-primary">Inofinix today</span>
                                {/* <span className="text-primary">Social Media Marketing</span> */}
                            </h1>
                            <p className="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and managing the website.</p>

                        </div>

                        <div className="home-dashboard">
                            <img src="/static/media/hero.61dada00.png" alt="" className="img-fluid mover" />
                        </div>
                    </div>
                    <div className="calendlyresponse" style={{marginTop:'-143px'}}>

                        {/* <!-- Calendly inline widget begin --> */}

                        <InlineWidget url="https://calendly.com/inofinix/30min" />

                        {/* <!-- Calendly inline widget end --> */}
                    </div>
                </section>

                <Popup />
            </React.Fragment>
        );
    }
}

export default Bookdemo;
