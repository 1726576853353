// React Basic and Bootstrap
import React, { Component } from 'react';
import Popup from '../../../components/Layout/popup';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import code3 from '../../../assets/images/circle.jpg';
import avatar from '../../../assets/images/avatar.png';

// import code2 from '../../../assets/images/coder33.png';
// import code0 from '../../../assets/images/coder44.png';
// import code4 from '../../../assets/images/coder22.png';
// import code1 from '../../../assets/images/coder11.png';
// import code5 from '../../../assets/images/coder5.png';

import gifimage03 from '../../../assets/images/gifimage03.gif';
import gif1 from '../../../assets/images/gif1.gif';
import gifimage06 from '../../../assets/images/gifimage06.gif';
import gifimage05 from '../../../assets/images/gifimage05.gif';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';

import Fade from 'react-reveal/Fade';
import { Motion, spring } from 'react-motion';
import { Link } from 'react-router-dom';
import { indigo } from '@mui/material/colors';
//Import Images

const springSettings = { stiffness: 170, damping: 26 };
const NEXT = 'show-next';
const Y_OFFSET_DIFFERENCE = 300;
// let photoList = [code0, code1, code2, code4, code5];

let photoList = [gifimage03, gifimage06, gifimage05,gif1 ];

let dataAosList = ['fade-right', 'zoom-out', 'zoom-in-down', 'flip-left'];
class Sectiontwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [
        [500, 350],
        [800, 600],
        [800, 400],
        [700, 500],
        [100, 350],
        [400, 200],
      ],
      currPhoto: 0,
      dataSource: photoList[0],
      // dataAos: dataAosList[0],

      imageStyle: {
        height: '30%',
        width: '25%',
        overflowY: 'scroll',
        display: 'none',
        // marginLeft: '270px',
      },
    };
  }

  componentDidMount() {
    AOS.init();

    document.body.classList = '';
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    document.getElementById('topnav').classList.add('bg-secondary-x');
    window.addEventListener('scroll', this.scrollNavigation, true);
    AOS.init({ duration: 2000 });
    AOS.refresh();
    // document.querySelector(".cookie-popup").classList.remove("show");
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }
  // console.log(dataSource);

  scrollNavigation = () => {
    const doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (navBar != null) {
      if (top > 80) {
        console.log(window.pageYOffset, 'hhhhh');
        if (window.pageYOffset < 3100) {
          this.setState({
            ...this.state,
            imageStyle: {
              height: '300px',
              width: '58%',
              overflowY: 'scroll',
              marginLeft:'-8px',
              position: 'sticky',
              top: '30%',
              // marginLeft: '283px',
            },
          });
        }
        if (window.pageYOffset < 3100 && window.pageYOffset > 1907) {
          this.setState({
            ...this.state,
            imageStyle: {
              height: '300px',
              width: '22%',
              overflowY: 'scroll',
              position: 'fixed',
              marginLeft:'18px',
              top: '30%',
              marginTop: '10px',
              // marginLeft: '270px',
            },
          });
        }
        navBar.classList.add('nav-sticky');
        document.getElementById('topnav').classList.remove('bg-secondary-x');
        const position = window.pageYOffset;
        const index =
          Math.floor(position / Y_OFFSET_DIFFERENCE) % photoList.length;
        const selectedSource = photoList[index];
        const selectedAos = dataAosList[index % dataAosList.length];

        if (
          selectedSource === this.state.dataSource &&
          selectedAos === this.state.dataAos
        ) {
          return;
        }

        this.setState({
          ...this.state,
          dataSource: selectedSource,
          dataAos: selectedAos,
        });
      } else {
        navBar.classList.remove('nav-sticky');
        document.getElementById('topnav').classList.add('bg-secondary-x');
      }
    }
  };
  handleChange = ({ target: { value } }) => {
    this.setState({ currPhoto: value });
  };
  clickHandler = (btn) => {
    console.log(btn);
    let photoIndex =
      btn === NEXT ? this.state.currPhoto + 1 : this.state.currPhoto - 1;
    photoIndex = photoIndex >= 0 ? photoIndex : this.state.photos.length - 1;
    photoIndex = photoIndex >= this.state.photos.length ? 0 : photoIndex;
    console.log(photoIndex);
    this.setState({
      currPhoto: photoIndex,
    });
  };
  render() {
    // const photoArray = [code0, code1, code2, code3, code4];

    const photoArray = [gifimage03, gifimage06, gifimage05, gif1];

    const { photos, currPhoto } = this.state;
    const [currWidth, currHeight] = photos[currPhoto];
    const widths = photos.map(([origW, origH]) => (currHeight / origH) * origW);
    const leftStartCoords = widths
      .slice(0, currPhoto)
      .reduce((sum, width) => sum - width, 0);
    const configs = [];
    photos.reduce((prevLeft, [origW, origH], i) => {
      configs.push({
        left: spring(prevLeft, springSettings),
        height: spring(currHeight, springSettings),
        width: spring(widths[i], springSettings),
      });
      return prevLeft + widths[i];
    }, leftStartCoords);
    return (
      <React.Fragment>
        <section style={{ marginTop: '90px' }}>
          <div className="main-content-wrapper cf">
            <div
              className="sqs-layout sqs-grid-12 columns-12"
              id="page-59fca8d46c3194f508016e47"
            >
              <div className="row sqs-row">
                <div className="col sqs-col-12 span-12">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    id="block-yui_3_17_2_87_1509726951338_3735"
                  >
                    <div
                      className="sqs-block-content"
                      style={{ marginRight: '136px' }}
                    >
                      <svg
                        style={{ width: '-webkit-fill-available' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="#2f55d4"
                        className="bi bi-speedometer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                        <path
                          fillRule="evenodd"
                          d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z"
                        />
                      </svg>
                      <h3
                        style={{
                          textAlign: 'center',
                          whiteSpace: 'pre-wrap',
                          color: 'grey',
                        }}
                        className="headingresponisve"
                      >
                        Introducing the{' '}
                        <a href="inofinix.com">Inofinix Cloud</a>
                      </h3>
                      <h1
                        style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
                        className="headingresponisve2"
                      >
                        <b>
                          {' '}
                          Increase your productivity and{' '}
                          <span style={{ color: '#2f55d4' }}>confidence </span>
                        </b>
                        <br></br>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            style={
              window.pageYOffset > 1942 || window.pageYOffset < 1941
                ? {
                    position: 'absolute',
                    background: '#fff',
                  }
                : {
                    position: 'absolute',
                    height: window.pageYOffset,
                    width: '4px',
                    background: 'rgb(47, 85, 212)',
                    marginLeft: '496px',
                  }
            }
          ></div> */}
          {/* <div
            style={{
              content: '',
              position: 'absolute',
              height: '1180px',
              // bottom: '1150px',
              left: '46.2%',
              borderLeft: ' 2px dashed #444',
            }}
            className="dashedlineresponsive"
          ></div> */}
          <div
            style={
              window.pageYOffset > 1925 && window.pageYOffset < 3080
                ? {
                    position: 'absolute',
                    height: window.pageYOffset - 1925,
                    width: '4px',
                    background: 'rgb(47, 85, 212)',
                    marginLeft: '496px',
                  }
                : {
                    display: 'none',
                  }
            }
          ></div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2,753px)',
              marginTop: '50px',
            }}
          >

            {/* .............Image Height............ */}
            <div>
              <img
                style={
                  window.pageYOffset > 2750
                    ? {
                        display: 'flex',
                      }
                    : this.state.imageStyle
                }
                src={this.state.dataSource}
                className="img-fluid imageresponsive"
                alt=""
              />
            </div>
            {/* .....................................Images..................................... */}
            {/* <div>
              <img
                data-aos={this.state.dataAos} // Apply the data-aos attribute
                src={this.state.dataSource} // Use the dataSource state
                className="img-fluid imageresponsive"
                alt=""
                style={{
                  ...this.state.imageStyle,
                  opacity: this.state.dataSource === gifimage03 ? 1 : 0,
                }}
              />
              <img
                data-aos={this.state.dataAos} // Apply the data-aos attribute
                src={this.state.dataSource} // Use the dataSource state
                className="img-fluid imageresponsive"
                alt=""
                style={{
                  ...this.state.imageStyle,
                  opacity: this.state.dataSource ===  ? 1 : 0,
                }}
              />
              <img
                data-aos={this.state.dataAos} // Apply the data-aos attribute
                src={this.state.dataSource} // Use the dataSource state
                className="img-fluid imageresponsive"
                alt=""
                style={{
                  ...this.state.imageStyle,
                  opacity: this.state.dataSource === gifimage05 ? 1 : 0,
                }}
              />
              <img
                data-aos={this.state.dataAos} // Apply the data-aos attribute
                src={this.state.dataSource} // Use the dataSource state
                className="img-fluid imageresponsive"
                alt=""
                style={{
                  ...this.state.imageStyle,
                  opacity: this.state.dataSource === gifimage06 ? 1 : 0,
                }}
              />
            </div> */}
            {/* <div>
              <img
                src={avatar}
                alt=""
                width="30px"
                height="30px"
                style={
                  // window.pageYOffset > 3100
                  //   ? {
                  //       display: 'none',
                  //     }
                  //   :
                  {
                    position: 'fixed',
                    transform: 'translate(-50%,-640%)',
                    left: '46.3%',
                    zIndex: '1',
                  }
                }
              />
            </div> */}
            <div style={{ marginBottom: '250px', marginLeft:'-731px' }}>
              <div
                className="paragraphresponsive"
                style={{
                  marginLeft: '540px',
                  marginBottom: '110px',
                  width: 'max-content',
                }}
              >
                <div>
                  <img
                    src={code3}
                    alt=""
                    width="15px"
                    height="15px"
                    className="paragraphresponsive dashedlineresponsive2"
                    style={{
                      position: 'absolute',
                      transform: 'translate(-50%,20%)',
                      left: '46.3%',
                    }}
                  />
                </div>
                <h3
                  style={{ color: '#2f55d4', fontSize: '27px' }}
                  className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                >
                  Optimize your runs for a faster feedback loop
                </h3>
                <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                  Run Inofinix in your existing CI pipeline and use test
                  parallelization, load <br></br> balancing, spec
                  prioritization, and more to be as efficient as possible with
                  your <br></br>available CI resources.
                </p>
                <Link
                  className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                  to={{ pathname: 'https://inofinix.com/index' }}
                  target="_blank"
                >
                  {' '}
                  Learn more
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="m9 12 4-4m0 0L9 4m4 4H3"
                      stroke="currentColor"
                      className="icon-dark"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </Link>
              </div>
              <div style={{ marginBottom: '250px' }}>
                <div
                  className="paragraphresponsive"
                  style={{
                    marginLeft: '540px',
                    marginBottom: '-150px',
                    width: 'max-content',
                  }}
                >
                  <div>
                    <img
                      src={code3}
                      alt=""
                      width="15px"
                      height="15px"
                      style={{
                        position: 'absolute',
                        transform: 'translate(-50%,20%)',
                        left: '46.3%',
                      }}
                      className="dashedlineresponsive2"
                    />
                  </div>
                  <h3
                    style={{ color: '#2f55d4' }}
                    className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                  >
                    Review and debug failures visually
                  </h3>
                  <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                    Reach new levels of visibility into why your tests failed in
                    CI. Play back videos <br></br>of your tests as they failed,
                    read friendly stack trace errors, and never guess<br></br>{' '}
                    why another test failed.
                  </p>
                  <Link
                    className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                    to={{ pathname: 'https://inofinix.com/index' }}
                    target="_blank"
                  >
                    {' '}
                    Learn more
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="m9 12 4-4m0 0L9 4m4 4H3"
                        stroke="currentColor"
                        className="icon-dark"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
              <div style={{ marginBottom: '250px' }}>
                <div
                  className="paragraphresponsive"
                  style={{
                    marginLeft: '540px',
                    marginBottom: '-150px',
                    width: 'max-content',
                  }}
                >
                  <div>
                    <img
                      src={code3}
                      alt=""
                      width="15px"
                      height="15px"
                      style={{
                        position: 'absolute',
                        transform: 'translate(-50%,20%)',
                        left: '46.3%',
                      }}
                      className="dashedlineresponsive2"
                    />
                  </div>
                  <h3
                    style={{ color: '#2f55d4' }}
                    className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                  >
                    Gain actionable insights into your test suite
                  </h3>
                  <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                    Monitor your test suite’s health with in-depth analytics.
                    Inofinix surfaces failing <br></br>and flaky test result
                    trends and config changes that affect your test suite’s{' '}
                    <br></br>performance.
                  </p>
                  <Link
                    className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                    to={{ pathname: 'https://inofinix.com/index' }}
                    target="_blank"
                  >
                    {' '}
                    Learn more
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="m9 12 4-4m0 0L9 4m4 4H3"
                        stroke="currentColor"
                        className="icon-dark"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>

              <div style={{ marginBottom: '170px' }}>
                <div
                  className="paragraphresponsive"
                  style={{
                    marginLeft: '540px',
                    // marginBottom: '-100px',
                    width: 'max-content',
                  }}
                >
                  <div>
                    <img
                      src={code3}
                      alt=""
                      width="15px"
                      height="15px"
                      style={{
                        position: 'absolute',
                        transform: 'translate(-50%,20%)',
                        left: '46.3%',
                      }}
                      className="dashedlineresponsive2"
                    />
                  </div>
                  <h3
                    style={{ color: '#2f55d4' }}
                    className="font-primary m-0  text-left text-[20px] font-semibold leading-[32px] transition transition-colors text-indigo-500"
                  >
                    Integrate seamlessly into your workflow
                  </h3>
                  <p className="mt-[8px] overflow-hidden text-[16px] leading-[24px] text-gray-700">
                    Plug Inofinix into any CI pipeline and you can manage test
                    results as a <br></br>team thanks to native integrations
                    with Slack, GitHub, GitLab, JIRA, and more.
                  </p>
                  <Link
                    className="mt-[12px] flex items-center gap-[8px] text-[16px] font-medium leading-[24px] text-indigo-500"
                    to={{ pathname: 'https://inofinix.com/index' }}
                    target="_blank"
                  >
                    {' '}
                    Learn more
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="m9 12 4-4m0 0L9 4m4 4H3"
                        stroke="currentColor"
                        className="icon-dark"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Popup />
      </React.Fragment>
    );
  }
}

export default Sectiontwo;
