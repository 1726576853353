import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import Carousel from 'react-bootstrap/Carousel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Import Icons
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import Images
import director from '../../../assets/images/client/director.jpg';
import hrr from '../../../assets/images/client/hrr.png';
// import gauthami from '../../../assets/images/client/gauthami.jpg';
import ceo from '../../../assets/images/client/ceo.jpg';
// import pradeep from '../../../assets/images/client/pradeep.jpg';
// import pooja from '../../../assets/images/client/pooja.jpeg';
// import sonalimisal from '../../../assets/images/client/sonalimisal.jpg';
// import akash from '../../../assets/images/client/akash.jpg';
// import darshan from '../../../assets/images/client/darshan.png';
// import gmahesh from '../../../assets/images/client/gmahesh.jpeg';
// import megha from '../../../assets/images/client/megha.jpg';
// import vijay from '../../../assets/images/client/vijay.jpg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const MindPower = () => { 
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       // className={className}
  //       style={{ ...style, display: "block", background: "black",color:'green'}}
  //       onClick={onClick} >
        
  //       <FontAwesomeIcon icon={faChevronRight} size="2x" className="slick-arrow-icon-right" />
  //     </div>
  //   );
  // }
  
  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       // className={className}
  //       style={{ ...style, display: "block", background: "black",color:'green' }}
  //       onClick={onClick}
  //     >
  //     <FontAwesomeIcon icon={faChevronLeft} size="2x" className="slick-arrow-icon-left" />
  //   </div>
  //   );
  // }
 
  // const settings = {
  //   className: "center",
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   cssEase: "ease",
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   useCSS: true
  // }

  return (
    <React.Fragment>
      <Container id="team"></Container>
      <Container data-aos="fade-up" style={{marginTop:"-50px"}}>
        <Row>
          <Col xs={12}>
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-4"> Management Team</h4>
              <h5 className="para-desc mx-auto mb-0">
                Mind power behind strategy and execution.
              </h5>
            </div>
          </Col>
        </Row>


        <Row >
          <div className="team1">
            {/* <Slider {...settings}> */}
            <div>
                <img className="gray"  src={director}  width="200px" height="200px" style={{borderRadius:'50%'}} />                
               <div style={{textAlign:'left'}}>
                <h5 className="mb-0">
                      Shivappa Tangade
                  </h5>
                  <p className="justify-content-left">Director & Founder</p>
                </div>
              </div>
              <div>
                <img className="gray"   src={ceo}  width="200px" height="200px" style={{borderRadius:'50%'}} />                
                <div style={{textAlign:'left'}}>
                <h5 className="mb-0">
                      Ningappa Kamate
                  </h5>
                  <p className="justify-content-left">Director & Founder</p>
                </div>
              </div>
            </div>
        </Row>
              
       


        
      </Container>
    </React.Fragment>
  );
};
export default MindPower;
