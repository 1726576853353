import React, { useEffect, useState } from "react";
import SectionTitle from "../../../components/Shared/SectionTitle";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { itSolutionWhatwedo } from "../../../common/data";
import Slider from "react-slick";

// import FeatherIcon
import FeatherIcon from "feather-icons-react";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../../node_modules/react-modal-video/scss/modal-video.scss";

// import image
import img1 from "../../../assets/images/client/amazon.svg";
import img2 from "../../../assets/images/client/google.svg";
import mongodb from "../../../assets/images/client/mongodb.svg";
import awscloud from "../../../assets/images/client/awscloud.png";
import GoLogoBlue from "../../../assets/images/client/GoLogoBlue.png";
import googlecloud from "../../../assets/images/client/googlecloud.png";
import grpc from "../../../assets/images/client/grpc.png";
import http from "../../../assets/images/client/http.png";
import influxdb from "../../../assets/images/client/influxdb.png";
import java from "../../../assets/images/client/java.png";
import microsoftazure from "../../../assets/images/client/microsoftazure.png";
import neo4j from "../../../assets/images/client/neo4j.png";
import nextcloud from "../../../assets/images/client/nextcloud.png";
import swiftlogo from "../../../assets/images/client/swiftlogo.png";

import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
// import { Typography } from "@mui/material/Typography";


const OurServices = () => {
  const partners = [
    { image: img1 },
    { image: img2 },
    { image: mongodb },
    { image: GoLogoBlue },
    { image: googlecloud },
    { image: http },
    { image: microsoftazure },
    { image: neo4j },
    // { image: nextcloud },
    { image: swiftlogo },
    { image: java },
    { image: awscloud },
    { image: grpc },
    { image: influxdb },
];

  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    AOS.init({duration:1000});
    AOS.refresh();
  }, []);
  const settings = {
    className: "center",
    infinite: true,
    speed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    // arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "ease",
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    useCSS: true
  }

  return (
    <React.Fragment>
      <section className="section">
        <Container data-aos='fade-up'>
          <Row className="justify-content-center">
            <div className="col-12 text-center">
              {/* render Section title */}
              <SectionTitle
                title="Our services"
                desc=" Our services are based on maximum automation & maximum innovation. Benefit from our high-quality custom solutions and services that suit your needs."
              />
            </div>
          </Row>
          <Row>
            {itSolutionWhatwedo.map((items, key) => (
              <Col lg={3} md={4} className="mt-4 pt-2" key={key} data-aos='fade-up'>
                <div className="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0 w-100 h-100">
                  <span className="h1 icon-color w-100 h-70">
                    <i className={items.icon}></i>
                  </span>
                  <div className="card-body p-0 content w-100 h-70">
                    <h5>{items.heading}</h5>
                    <p className="para text-muted mb-0 w-100 h-70">{items.desc}</p>
                  </div>
                  <span className="big-icon text-center">
                    <i className={items.icon}></i>
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
        <section className="section bg-light" style={{height:"358px"}}>
        <Container  data-aos='fade-up'>
          <div style={{margin:'40px'}} >
          {/* partners */}
          <Row className="justify-content-center partnerimageresponse headingresponse" >
           
          
          <SectionTitle 
                title="Technology Partners"
                desc="We aspire to collaborate with & integrate the cutting edge technology stacks."
              />
         
            <Slider {...settings} >
            {partners.map((partner, key) => (
              <Row
                lg={2}
                md={2}
                xs={2}
                key={key}
                className="text-center py-4"
                style={{marginTop:'10px'}}
              >
                <img
                  src={partner.image}
                  // className="avatar avatar-sm"
                  className="ph"
                  alt="Inofinix"
                />
              </Row>
            ))}
              </Slider>
          </Row>
          </div>
        </Container>
        </section> 
      
    </React.Fragment>
  );
};
export default OurServices;
