// React Basic and Bootstrap
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { reviews } from "../../common/data";

//Import Containers
import ReviewsSlider from "../../components/Shared/ReviewsSlider";

const Client = () => {
  return (
    <React.Fragment>
      <Container className="mt-60 mb-30">
        {/* clients slider */}
        <ReviewsSlider reviews={reviews} colClass="mt-4" />
      </Container>
    </React.Fragment>
  );
}

export default Client;
