import React from "react";
import SectionTitle from "../../../components/Shared/SectionTitle";

import { Card, Col, Container, Row } from "reactstrap";

import vision from "./Images/vision.svg";
const Section3 = () => {
    return (
      <React.Fragment>
        <Container  id="vision" data-aos= 'fade-up'></Container>

        <SectionTitle
                title="Vision and Mission"
                desc=" Our services are based on maximum automation & maximum innovation. Benefit from our high-quality custom solutions and services that suit your needs."
              />
          <Container>
            <div className="mt-80 mt-40 container">
              <div className="align-items-center row">
                <div className="mt-4 pt-2 order-2 col-md-6 order-md-1 col-lg-7" data-aos='fade-up'>
                  <div className="section-title me-lg-5" style={{ marginTop: '-40px'}}>
                    <h4 className="title mb-4">Our Vision</h4>
                    <p className="text-muted">To be a world class technology company helping our clients to innovate and succeed.</p>
                    <br>
                    </br>
                    <br>
                    </br>
                    <br>
                    </br>
                    <h4 className="title mb-4">Our Mission</h4>
                    <p className="text-muted">To offer the cutting-edge technology solutions to the organisations across the globe to achieve their aspirations</p>

{/*                     <ul className="list-unstyled text-muted">
                      <li className="mb-1">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
                      <li className="mb-1">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i></span>Our Talented &amp; Experienced Marketing Agency</li><li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
                    </ul> */}
                    {/* <div className="mt-4"><a className="btn btn-primary" href="/index-insurance">Read More <i><i className="uil uil-angle-right-b align-middle"></i></i></a>
                    </div> */}
                    </div>
                    </div>
                     
                <Col lg={5} >
                  <img src={vision} className="img-fluid" alt="" />
                </Col>
              </div></div>

          </Container>
        
       
        </React.Fragment>
           );  
        };
        export default Section3;