import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../../components/Shared/SectionTitle";


import CountUp from "react-countup";
import classnames from "classnames";
import { Card, Col, Container, Row } from "reactstrap";
const Section4 = () => {
  return (
    <React.Fragment>
      <Container id="values"></Container>
      <section className="section">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <SectionTitle
              title="Our Values"
              desc=" Our core values define who we are, our culture deines the way we engage with our clients, create and deliver solutions."
            />
          </div>
        </div>
        <Container className="aboutdescr" >
          <div className="container">

            <div>
              <div id="counter" className="me-lg-4 row">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="mt-4 mt-lg-0 pt-2 pt-lg-0 col-12">
                      <div className="features feature-primary explore-feature border-0 rounded text-center shadow card">
                        <div className="card-body">
                          <div className="icons rounded-circle shadow-lg d-inline-block"><i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16">
                              <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path>
                            </svg></i>
                          </div>
                          <h5 className="mt-3 title">Teamwork</h5>

                        </div>
                      </div>
                    </div>

                    <div className="mt-4 pt-2 col-12">
                      <div className="features feature-primary explore-feature border-0 rounded text-center shadow card">
                        <div className="card-body"><div className="icons rounded-circle shadow-lg d-inline-block"><i>
                          <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" className="feather feather-pen-tool fea"><g>
                            <path d="M12 19l7-7 3 3-7 7-3-3z"></path><path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path>
                            <path d="M2 2l7.586 7.586"></path><circle cx="11" cy="11" r="2"></circle></g></svg>
                        </i></div>
                          <h5 className="mt-3 title">Persue Excellence </h5>
                        </div>
                      </div>
                    </div>


                    <div className="mt-4 pt-2 col-12"><div className="features feature-primary explore-feature border-0 rounded text-center shadow card">
                      <div className="card-body">
                        <div className="icons rounded-circle shadow-lg d-inline-block"><i><svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" className="feather feather-aperture fea"><g>
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="14.31" y1="8" x2="20.05" y2="17.94"></line>
                          <line x1="9.69" y1="8" x2="21.17" y2="8"></line>
                          <line x1="7.38" y1="12" x2="13.12" y2="2.06"></line>
                          <line x1="9.69" y1="16" x2="3.95" y2="6.06"></line>
                          <line x1="14.31" y1="16" x2="2.83" y2="16"></line>
                          <line x1="16.62" y1="12" x2="10.88" y2="21.94"></line></g></svg></i></div>
                        <h5 className="mt-3 title">Always Learning</h5>

                      </div></div></div></div></div>

                <div className="col-12 col-md-6">
                  <div className="pt-lg-4 mt-lg-4 row">
                    <div className="mt-4 pt-2 col-12">
                      <div className="features feature-primary explore-feature border-0 rounded text-center shadow card">
                        <div className="card-body"><div className="icons rounded-circle shadow-lg d-inline-block"><i>
                          <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" className="feather feather-smartphone fea"><g>
                            <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></g></svg></i></div>
                          <h5 className="mt-3 title">Trust and Integrity</h5>
                        </div></div></div>
                    <div className="mt-4 pt-2 col-12"><div className="features feature-primary explore-feature border-0 rounded text-center shadow card">
                      <div className="card-body">
                        <div className="icons rounded-circle shadow-lg d-inline-block"><i>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="feather feather-message-circle fea"><g>
                            <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></g></svg></i></div>
                        <h5 className="mt-3 title">Diversity</h5>
                      </div>

                    </div>
                    </div>  </div>
                </div>

              </div>

            </div>

            <div>

            </div>
          </div>
          <div>
            <div className="textBackground" style={{ padding: '10px', backgroundColor: 'whitesmoke', marginBottom: '12px' }} data-aos='fade-up'>
              <Col xs={12}>
                <div style={{ width: '550px', marginLeft:'-16px' }}>
                  <div className="section-title">
                    <h4 className="title "><i className="uil uil-angle-double-right text-primary"></i>Teamwork</h4>

                    <ul className="list-unstyled me-auto">
                      <li className="mb-1  d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We believe teamwork provides improved efficiency and productivity</li>
                      <li className="mb-1  d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We value every team member and their unique contributions</li>
                      <li className="mb-1  d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We put the most skilled, collaborative & engaged team</li>
                      <li className="mb-1  d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>Together we overcome challenges and together we celebrate success</li>
                      <li className="mb-1  d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We collabrate as one team, by co-creating with our clients and partners</li>
                    </ul>

                  </div>
                </div>
              </Col>
            </div>

            <div className="textBackground" style={{ padding: '10px', backgroundColor: 'whitesmoke', marginBottom: '12px' }} data-aos='fade-up'>
              <div className="textAbout">
                <div className="section-title">
                  <h4 className="title "><i className="uil uil-angle-double-right text-primary"></i>Persue Excellence</h4>

                  <ul className="list-unstyled me-auto">
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We innovate by trying new ways of doing things & improving every day</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We strive to exceed our client's expectation and set the bar higher</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We work as a team by helping each other and asking for help</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We are driven by the value of creating exceptional exercises for employees and our clients alike</li>
                  </ul>

                </div>
              </div>
            </div>
            <div className="textBackground" style={{ padding: '10px', backgroundColor: 'whitesmoke', marginBottom: '12px', width: '600px' }} data-aos='fade-up'>
              <div className="textAbout">
                <div className="section-title">
                  <h4 className="title "><i className="uil uil-angle-double-right text-primary"></i>Always Learning</h4>

                  <ul className="list-unstyled me-auto">
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>Creating knowledge through doing is the core of what we do</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We take personal initiative to learn,be informed and gain new knowledge & skills</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We foster an environment where we see how we can improve ourselves personally and professionally</li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="textBackground" style={{ padding: '10px', backgroundColor: 'whitesmoke', marginBottom: '12px' }} data-aos='fade-up'>
              <div className="textAbout">
                <div className="section-title">
                  <h4 className="title "><i className="uil uil-angle-double-right text-primary"></i>Trust and Integrity</h4>

                  <ul className="list-unstyled me-auto">
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We work with integrity in everything we do</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We treat clients and colleagues honestly and openly while respecting their confidentiality</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We believe that anything we do should build trust and maintain integrity</li>
                  </ul>


                </div>
              </div>
            </div>
            <div className="textBackground" style={{ padding: '10px', backgroundColor: 'whitesmoke', marginBottom: '12px' }} data-aos='fade-up'>
              <div className="textAbout">
                <div className="section-title">
                  <h4 className="title "><i className="uil uil-angle-double-right text-primary"></i>Diversity</h4>

                  <ul className="list-unstyled me-auto">
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We respect,appreciate and celebrate differences</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We embrace cross culture diversity and open to new experience</li>
                    <li className="mb-1 d-flex"><span className="text-primary"><i className="uil uil-check-circle align-middle p-2"></i></span>We believe diverse teams boost creativity and innovations</li>
                  </ul>

                </div>
              </div>
            </div>
          </div>

        </Container>
      </section>
    </React.Fragment>
  );
};
export default Section4;