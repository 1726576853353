import React from 'react';
import NavBar from './Navbar';
import Section from './Section';
import Categories from './Categories';
import CTA from './CTA';
import Footer3 from '../../../pages/Pages/PageFooterLayouts/Footer3';
import ThemeSwitcher from '../../../components/Layout/ThemeSwitcher';
import OurServices from './OurServices';
import Features from './Features';
import WorkProcess from './WorkProcess';
import MindPower from './MindPower';
import Blog from './Blog';
import ReviewsSlider from "../../../components/Shared/ReviewsSlider";
import SectionTitle from "../../../components/Shared/SectionTitle";
import { reviews } from "../../../common/data";
import Client from '../../Saas/Client';
import TopbarIndex from '../../../components/Layout/TopbarIndex';
import NewFooter from '../PageFooterLayouts/NewFooter';
import WhyInofinix from './WhyInofinix';
const Index = () => {
  return (
    <React.Fragment>
    <TopbarIndex />
      <Section />
      {/* <Categories /> */}
      <section className="section">
      <OurServices />
      </section>
      <section className="section backgroundimage">
      <Features  />
      </section>
      {/* <CTA/> */}
      <section className="section bg-light">
        <WorkProcess />
        </section>
        <section className="section">
          <WhyInofinix />
        </section>
      
{/*         <section className="section bg-light">
              <SectionTitle 
                          title="Client Reviews"
                          desc="Here is what our clients say (contact us to get the inperson reference to talk to)"
                                        />
              {/ * <ReviewsSlider reviews={reviews} colClass="mt-4" /> * /}
              <Client />
      </section> */}

      {/* <section className="section bg-light">
        <Blog  />
      </section> */}
          <NewFooter />
      {/* <ThemeSwitcher/> */}
    </React.Fragment>
  );
};

export default Index;
