import React, { Component } from "react";
//Import file
import Section from "./Section";
import Feature from "./Feature";
import KeyFeature from "./KeyFeature";
import ExperienceTeam from "./ExperienceTeam";
import Pricing from "./Pricing";
import Client from "./Cilent";
import Blog from "../ITSolution/Blog";
import Popup from "../../components/Layout/popup";
import AOS from "aos";
import "aos/dist/aos.css";

export default class index extends Component {
  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
    });
    AOS.init({duration:2000});
    AOS.refresh();

    document.getElementById("topnav").classList.add("bg-secondary-x");

    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null){
    if (top > 80) {
      // document.querySelector(".shoppingbtn").classList.remove("btn-light");
      // document.querySelector(".shoppingbtn").classList.add("btn-primary");
      document.getElementById("topnav").classList.add("nav-sticky");
      document.getElementById("topnav").classList.remove("bg-secondary-x");
    } else {
      // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
      // document.querySelector(".shoppingbtn").classList.add("btn-light");
      document.getElementById("topnav").classList.remove("nav-sticky");
      document.getElementById("topnav").classList.add("bg-secondary-x");
    }
  }
  };

  render() {
    return (
      <React.Fragment>        
       
        {/* import Section */}
        <Section />

        {/* import Feature */}
        <Feature />

        {/* Key Feature */}
        <KeyFeature />

        <Popup/>
      </React.Fragment>
    );
  }
}
